import { fetchUtils } from "react-admin";
import { getAuthHeaders, getAccessToken } from "./authUtils";
import { apiUrl, mytvsuperUrl } from "./constant";
import decodeJwt from "jwt-decode";
import Resizer from "react-image-file-resizer";
import { convert_IdToId, convertIdTo_Id, getIMStatus } from "./resources/helper";
import { convertJsonToRegion, convertRegionToJson } from "./resources/component/region-components";

import { replace } from "lodash";

// resize posters dim
const portraitSmallSize = { width: 128, height: 186 };
const portraitMediumSize = { width: 200, height: 290 };
const portraitLargeSize = { width: 743, height: 1080 };

const landscapeSmallSize = { width: 324, height: 182 };
const landscapeMediumSize = { width: 615, height: 346 };
const landscapeLargeSize = { width: 1920, height: 1080 };

// resize posters function
const resizeFile = (file, width, height) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width, // max w
      height, // max h
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob",
      null, // min w
      null // min h
    );
  });

var moment = require("moment-timezone");
var _ = require("lodash");

const httpClient = fetchUtils.fetchJson;

//* Convert resBlob to base64
export const blobToData = (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

/** Convert to HK time*/

const HKDate = (date) => {
  return date;
  // const time = new Date(date).getTime();
  // const hktime = time - 28800 * 1000;
  // return new Date(hktime);
};
const HKTime = (date) => {
  return date;
  // const time = new Date(date).getTime();
  // const hktime = time + 28800 * 1000;
  // return new Date(hktime);
};

const dataProvider = {
  getClipsAutocomplete: async (resource, params) => {
    console.log("getClipsAutocomplete");

    if (Object.keys(params.filter).length == 0) {
      return { data: [], total: 0 };
    }

    if (!params.filter["clip_type"]) {
      return { data: [], total: 0 };
    }

    if (!params.filter["name_tc"]) {
      return { data: [], total: 0 };
    }

    const queryObj = {
      sort: "-id",
      page: 1,
      limit: 99999,
    };

    queryObj.where = {
      $and: [
        {
          $expr: {
            $regexMatch: {
              input: { $toString: `$name_tc` },
              regex: ".*" + _.escapeRegExp(params.filter["name_tc"]) + ".*",
              options: "i",
            },
          },
        },
        {
          type: params.filter["clip_type"],
        },
      ],
    };

    let json;
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/clips?query=${query}`;

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    var res = [];
    for (const item of json.data) {
      item.name_tc = item.name_tc + " - " + item.clip_id;
      item._id = item.clip_id;

      res.push(item);

      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log(json);

    return {
      data: res,
      total: json.total,
    };
  },

  getClipsType: async (resource, params) => {
    console.log("getClipsType");

    const url = `${apiUrl}/clips/type`;
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    var result = json.data.map((clipType) => {
      return { id: clipType, name: clipType };
    });

    return {
      data: result,
      total: result.length,
    };
  },

  getMenuTreeCategoryById: async (categoryId) => {
    console.log("getMenuTreeCategoryById");

    const url = `${apiUrl}/menu-tree/category-id/${categoryId}`;
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    return {
      data: json,
    };
  },

  getProgrammeByProgrammeId: async (programmeId) => {
    console.log("getProgrammeByProgrammeId : programmeId = " + programmeId);

    var query = {
      where: {
        programme_id: parseInt(programmeId),
      },
    };

    const url = `${apiUrl}/programmes?query=` + JSON.stringify(query);
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    console.log("getProgrammeByProgrammeId : json = ");
    console.log(json);

    if (json.data.length > 0) {
      const result = json.data[0];
      delete Object.assign(result, { ["id"]: result["_id"] })["_id"];
      console.log(result);
      return {
        data: result,
      };
    } else {
      return { data: null };
    }
  },
  getMpmRowScheduleTypeByType: async (type) => {
    console.log("getMpmRowScheduleTypeByType");

    var query = {
      where: {
        type: type,
      },
    };

    const url = `${apiUrl}/mpm-row-schedule-types?query=` + JSON.stringify(query);

    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    if (json.data.length > 0) {
      const result = json.data[0];
      delete Object.assign(result, { ["id"]: result["_id"] })["_id"];
      console.log(result);
      return {
        data: result,
      };
    } else {
      return { data: null };
    }
  },

  createImage: async (formData) => {
    var data = {};

    data["type"] = formData.type;
    data["platform"] = formData.platform;
    data["image_base64"] = await blobToData(formData.image_base64.rawFile);

    const url = `${apiUrl}/images`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      // body: JSON.stringify(render),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },
  updateImage: async (id, formData) => {
    var data = {};

    data["type"] = formData.type;
    data["platform"] = formData.platform;
    data["image_base64"] = await blobToData(formData.image_base64.rawFile);

    const url = `${apiUrl}/images/${id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      // body: JSON.stringify(render),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },
  getKeyValueByKey: async (key) => {
    console.log("getKeyValuesByKey");

    var query = {
      where: {
        key: key,
      },
    };

    const url = `${apiUrl}/key-values?query=` + JSON.stringify(query);
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    const keyValue = json.data[0];
    delete Object.assign(keyValue, { ["id"]: keyValue["_id"] })["_id"];
    console.log(keyValue);
    return {
      data: keyValue,
    };
  },

  getImageByNameAndPlatform: async (name, platform) => {
    console.log("getImageByNameAndPlatform");

    var query = {
      where: {
        type: name,
        platform: platform,
      },
    };

    const url = `${apiUrl}/images?query=` + JSON.stringify(query);

    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    var data = null;
    if (json.data.length > 0) {
      data = json.data[0];
    }
    // delete Object.assign(page, { ["id"]: page["_id"] })["_id"];
    return {
      data: data,
    };
  },

  getMpmPageByCategoryId: async (categoryId) => {
    console.log("getMpmPageByCategoryId : categoryId = " + categoryId);

    var query = {
      where: {
        category_id: parseInt(categoryId),
      },
    };

    const url = `${apiUrl}/mpm-pages?query=` + JSON.stringify(query);
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    console.log("getMpmPageByCategoryId : json = ");
    console.log(json);

    const page = json.data[0];
    delete Object.assign(page, { ["id"]: page["_id"] })["_id"];
    console.log(page);
    return {
      data: page,
    };
  },
  getMpmRowTemplatesByLayouts: async () => {
    console.log("getMpmRowTemplatesByLayouts");

    var query = {
      // where: {
      //   layout: {
      //     $in: params,
      //   },
      // },
      sort: "name",
      limit: 1000,
    };

    const url = `${apiUrl}/mpm-row-templates?query=` + JSON.stringify(query);
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },

  getMpmRowTagAutocomplete: async (params) => {
    console.log("getMpmRowTagAutocomplete");
    console.log(params);

    if (!params.filter["tag_type"] || !params.filter["name_tc"]) {
      console.log("return empty");

      return {
        data: [],
        total: 0,
      };
    }

    var query = {
      where: {
        $or: [
          {
            $and: [
              {
                type: params.filter["tag_type"],
              },
              {
                $expr: {
                  $regexMatch: {
                    input: { $toString: `$name_tc` },
                    regex: ".*" + _.escapeRegExp(params.filter["name_tc"]) + ".*",
                    options: "i",
                  },
                },
              },
            ],
          },
          {
            $and: [
              {
                type: params.filter["tag_type"],
              },
              {
                $expr: {
                  $regexMatch: {
                    input: { $toString: `$name_en` },
                    regex: ".*" + _.escapeRegExp(params.filter["name_tc"]) + ".*",
                    options: "i",
                  },
                },
              },
            ],
          },
        ],
      },
    };
    console.log("query");
    console.log(query);

    const url = `${apiUrl}/tags?query=` + JSON.stringify(query);
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["tag_id"] })["_id"];
    }
    console.log("json");
    console.log(json);

    return {
      data: json.data,
      total: json.total,
    };
  },
  searchProgrammesByKeyword: async (params) => {
    console.log("dataProvider . searchProgrammesByKeyword : params = ");
    console.log(params);

    if (Object.keys(params.filter).length == 0) {
      return { data: [], total: 0 };
    }

    var query = {
      page: 1,
      limit: 999999,
      where: {
        $or: [
          {
            $expr: {
              $regexMatch: {
                input: { $toString: `$programme_id` },
                regex: ".*" + _.escapeRegExp(params.filter["name_tc"]) + ".*",
                options: "i",
              },
            },
          },
          {
            $expr: {
              $regexMatch: {
                input: { $toString: `$name_tc` },
                regex: ".*" + _.escapeRegExp(params.filter["name_tc"]) + ".*",
                options: "i",
              },
            },
          },
        ],
      },
    };

    const url = `${apiUrl}/programmes?query=` + JSON.stringify(query);

    console.log("url = " + url);

    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    console.log("dataProvider . searchProgrammesByKeyword : json = ");
    console.log(json);

    for (const item of json.data) {
      item.name_tc = item.name_tc + " - " + item.programme_id;
      item._id = item.programme_id;
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . searchProgrammesByKeyword : result = ");
    console.log({
      data: json.data,
      total: json.total,
    });

    return {
      data: json.data,
      total: json.total,
    };
  },
  getTagTypes: async () => {
    console.log("getTagTypes");

    const url = `${apiUrl}/tags/type`;
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });
    console.log(json);
    return {
      data: json.data.map((p) => ({ id: p, name: p })),
      total: json.data.length,
    };
  },

  getPermissionList: async () => {
    console.log("dataProvider . getPermissionList");

    const url = `${apiUrl}/roles/permissions`;
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });
    console.log("dataProvider . getPermissionList : json");
    console.log(json);

    return {
      data: json.map((p) => ({ id: p, name: p })),
      total: json.length,
    };
  },

  getPreviewSlot: async (type, previewDate) => {
    //NOTE: previewDate is moment object

    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
    //It always in format YYYY-MM-DDTHH:mm:ss.sssZ, and zero UTC. So, it doesn't matter if previewDate is in UTC+8 or UTC+0 timezone
    var previewDateString = previewDate.toISOString();

    console.log("dataProvider . getPreviewSlot : type = " + type + " , previewDate = " + previewDateString);

    // const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;
    // const queryObj = {
    //   sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
    //   page: page,
    //   limit: perPage,
    // };
    //  console.log(params);
    // queryObj.where = { type: params.filter.type };
    // queryObj.where = { type: params.filter.type };

    // const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/mpm-row-schedules/preview?type=${type}&previewDate=${previewDateString}`;
    console.log("dataProvider . getPreviewSlot : url = " + url);
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });
    console.log("dataProvider . getPreviewSlot : json = ");
    console.log(json);

    if (json.length > 0) {
      for (const item of json) {
        // eslint-disable-next-line
        delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
      }
    }
    return {
      data: json,
    };

    // return {
    //   data: json,
    //   total: json.length,
    // };
  },

  // getPreviewOkBuySlot: async (resource, params) => {
  //   console.log("getPreviewSlot");
  //   const url = `${apiUrl}/mpm-row-schedules/preview?type=editorial_okbuy&previewDate=${params.filter.previewDate}`;
  //   const { json } = await httpClient(url, {
  //     method: "GET",
  //     headers: await getAuthHeaders(),
  //   });
  //   for (const item of json) {
  //     // eslint-disable-next-line
  //     delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
  //   }
  //   return {
  //     data: json,
  //     total: json.length,
  //   };
  // },

  // getPreviewSponsorSlot: async (resource, params) => {
  //   console.log("getPreviewSlot");
  //   const url = `${apiUrl}/mpm-row-schedules/preview?type=editorial_sponsor&previewDate=${params.filter.previewDate}`;
  //   const { json } = await httpClient(url, {
  //     method: "GET",
  //     headers: await getAuthHeaders(),
  //   });
  //   for (const item of json) {
  //     // eslint-disable-next-line
  //     delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
  //   }
  //   return {
  //     data: json,
  //     total: json.length,
  //   };
  // },
  // getPreviewHorseRace1: async (resource, params) => {
  //   console.log("getPreviewHorserace1");
  //   const url = `${apiUrl}/mpm-row-schedules/preview?type=horserace_1&previewDate=${params.filter.previewDate}`;
  //   const { json } = await httpClient(url, {
  //     method: "GET",
  //     headers: await getAuthHeaders(),
  //   });
  //   for (const item of json) {
  //     // eslint-disable-next-line
  //     delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
  //   }
  //   return {
  //     data: json,
  //     total: json.length,
  //   };
  // },
  // getPreviewHorseRace2: async (resource, params) => {
  //   console.log("getPreviewHorserace2");
  //   const url = `${apiUrl}/mpm-row-schedules/preview?type=horserace_2&previewDate=${params.filter.previewDate}`;
  //   const { json } = await httpClient(url, {
  //     method: "GET",
  //     headers: await getAuthHeaders(),
  //   });
  //   for (const item of json) {
  //     // eslint-disable-next-line
  //     delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
  //   }
  //   return {
  //     data: json,
  //     total: json.length,
  //   };
  // },

  getSlots: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };
    const filterKeys = Object.keys(params.filter);
    console.log(filterKeys);

    if (filterKeys.length !== 0) {
      const filter = [];

      let expr;
      let type = { type: params.filter["type"] };
      // if (params.filter["type"].length !== 0) {
      //   type = { type: params.filter["type"] };
      // }
      expr = { slot_id: parseInt(params.filter["slot_id"]) };
      filter.push(expr);
      filter.push(type);
      queryObj.where = { $and: filter };
    }

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/mpm-row-schedules?query=${query}`;

    console.log("getSlots : url = " + url);

    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    console.log("getSlots : json = ");
    console.log(json);

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
      item.portrait_poster_custom = item.portrait_poster;
      item.landscape_poster_custom = item.landscape_poster;
      item.region = convertJsonToRegion(item);
    }
    // return { data: [], total: 1 };
    return {
      data: json.data,
      total: json.total,
    };
  },

  getListHotSearch: async (resource, params) => {
    console.log("getListHotSearch");
    // const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;
    var where = {};
    if (params.filter.profile_class) {
      where["profile_class"] = params.filter.profile_class;
    }

    if (params.filter.search) {
      where["search"] = { $regex: params.filter.search, $options: "i" };
    }
    if (params.filter.enabled) {
      where["enabled"] = params.filter.enabled;
    }

    const queryObj = {
      where: where,
      sort: "order",
      page: 1,
      limit: 99999,
    };

    // const filterKeys = Object.keys(params.filter);
    // if (filterKeys.length !== 0) {
    //   const filter = [];
    //   filterKeys.forEach((key) => {
    //     let expr;
    //     switch (typeof params.filter[key]) {
    //       case "string": {
    //         if (key == "tags") {
    //           expr = { tags: params.filter[key] };
    //         } else {
    //           expr = {
    //             $expr: {
    //               $regexMatch: {
    //                 input: { $toString: `$${key}` },
    //                 regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
    //                 options: "i",
    //               },
    //             },
    //           };
    //         }

    //         break;
    //       }
    //       default: {
    //         if (key == "tags") {
    //           expr = { tags: params.filter[key] };
    //         } else {
    //           expr = { key: params.filter[key] };
    //         }
    //       }
    //     }
    //     filter.push(expr);
    //   });
    //   queryObj.where = { $and: filter };
    // }
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/hot-searchs?query=${query}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    //console.log(json);
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }
    return {
      data: json.data,
      total: json.total,
    };
  },

  getSpecialPrograms: async (params) => {
    console.log("getSpecialPrograms");
    console.log(params);

    //1. Get all programmes belong to this AdGroup
    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    queryObj.where = {
      is_sprog: true,
    };

    const url = `${apiUrl}/programmes?query=` + JSON.stringify(queryObj);
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },
  getAdGroupPrograms: async (params) => {
    console.log("getAdGroupPrograms");
    console.log(params);

    //1. Get all programmes belong to this AdGroup
    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    const url = `${apiUrl}/ads-adgroups/${params.filter.id}?query=` + JSON.stringify(queryObj);
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    json = json.programmes;

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },
  getAdGroupsProgramsSearch: async (params) => {
    console.log("getAdGroupsProgramsSearch");
    console.log(params);

    var tagIds = [];
    if (params.filter.tags) {
      for (const tag of params.filter.tags) {
        if (tag && tag.tag_value) {
          tagIds.push(tag.tag_value);
        }
      }
    }

    if (tagIds.length == 0 && !params.filter.programme_id) {
      return {
        data: [],
        total: 0,
      };
    }

    //1. Get all programmes
    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    var whereArr = [];
    if (tagIds.length > 0) {
      whereArr.push({
        tags: {
          $all: tagIds,
        },
      });
    }
    if (params.filter.programme_id) {
      whereArr.push({ programme_id: parseInt(params.filter.programme_id) });
    }
    queryObj.where = {
      $or: whereArr,
    };

    const url = `${apiUrl}/programmes?query=` + JSON.stringify(queryObj);
    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    //2. Get all adGroups that programmes belong to
    var programmeIds = json.data.map((d) => d.programme_id);
    console.log("prorgammeIds = " + programmeIds);

    const query = programmeIds;

    const url2 = `${apiUrl}/ads-adgroups/programme_ids?ids=` + JSON.stringify(query);
    const response = await httpClient(url2, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    //3. Merge 1 & 2 data
    for (const item of json.data) {
      for (const d of response.json.data) {
        if (d.programme_ids.programme_id == item.programme_id) {
          item.adgroup_name = d.name;
          item.adgroup_start_date = d.start_date;
          item.adgroup_end_date = d.end_date;
          break;
        }
      }
    }

    return {
      data: json.data,
      total: json.total,
    };
  },
  getChannelNoListWithPreviewTime: async (params) => {
    console.log("getChannelNoListWithPreviewTime");
    console.log(params);

    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    var query = {};
    if (params.filter.channel_no) {
      query["channel_no"] = parseInt(params.filter.channel_no);
    }
    if (params.filter.network_code) {
      query["network_code"] = params.filter.network_code;
    }

    queryObj.where = query;

    const url = `${apiUrl}/channels/preview?query=` + JSON.stringify(queryObj) + `&preview_time=${params.filter.preview_time}&show_all=${params.filter.show_all}`;
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },
  getChannelNoList: async (params) => {
    console.log("getChannelNoList");
    console.log(params);

    const queryObj = {
      sort: "channel_no",
      page: 1,
      limit: 99999,
    };

    const url = `${apiUrl}/channel-no-list?query=` + JSON.stringify(queryObj);
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },
  getChannelList: async (params) => {
    console.log("getChannelList");
    console.log(params);

    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    var query = {};
    if (params.filter.channel_no) {
      query["channel_no"] = parseInt(params.filter.channel_no);
    }
    if (params.filter.network_code) {
      query["network_code"] = params.filter.network_code;
    }

    queryObj.where = query;

    const url = `${apiUrl}/channels?query=` + JSON.stringify(queryObj);
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      item.region = convertJsonToRegion(item);

      item.made_for_kid = false;
      if (item.profile_class.includes("kids")) {
        item.made_for_kid = true;
      }

      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },
  getList: async (resource, params) => {
    console.log("getList : resource = " + resource + " , params = ");
    console.log(params);

    let originalResource = "";

    if (resource == "mpm-rows") {
      return dataProvider.getListMpmRows(resource, params);
    }
    if (resource == "menu-tree") {
      return dataProvider.getListMenuTree(resource, params);
    }
    if (resource == "vipo-blacklist") {
      return dataProvider.getListVipoBlacklist(resource, params);
    }
    if (resource == "channel-ordering") {
      return dataProvider.getListChannelOrdering();
    }
    if (resource == "profile-icons" || resource == "kids-profile-icons") {
      return dataProvider.getListProfileIcons(resource, params);
    }

    if (resource == "tags-autocomplete") {
      return dataProvider.getListTagsAutocomplete(resource, params);
    }

    if (resource == "profiles-preferences" || resource == "kids-profiles-preferences") {
      return dataProvider.getListProfilesPreferences(resource, params);
    }
    if (resource == "im-canned-messages") {
      return dataProvider.getListIMCannedMessages(resource, params);
    }

    if (resource == "im-rooms") {
      return dataProvider.getListIMChatrooms(resource, params);
    }

    if (resource == "episode-preview-schedules") {
      return dataProvider.getEpisodePreviewScheduleList(params);
    }

    if (resource == "subcat-filters") {
      return dataProvider.getSubcatFiltersList(resource, params);
    }

    if (resource == "menu-tree/preview" || resource == "kids-menu-tree/preview") {
      return dataProvider.getMenuTreePreview(resource, params);
    }

    if (resource == "channel-nos") {
      return dataProvider.getChannelNoListWithPreviewTime(params);
    }

    if (resource == "channels") {
      return dataProvider.getChannelList(params);
    }

    if (resource == "special-program-programs") {
      return dataProvider.getSpecialPrograms(params);
    }

    if (resource == "ads-adgroups-programs") {
      return dataProvider.getAdGroupPrograms(params);
    }

    if (resource == "ads-adgroups-programs-search") {
      return dataProvider.getAdGroupsProgramsSearch(params);
    }
    if (resource == "mpm-row-tag-autocomplete") {
      return dataProvider.getMpmRowTagAutocomplete(params);
    }
    if (resource == "tags/type") {
      return dataProvider.getTagTypes();
    }
    if (resource == "permissions") {
      return dataProvider.getPermissionList();
    }

    if (resource == "push-notifications") {
      return dataProvider.getListNotifications(resource, params);
    }

    if (resource == "events") {
      return dataProvider.getListEvent(resource, params);
    }

    if (resource == "categories") {
      return dataProvider.getListCategories(resource, params);
    }
    if (resource == "editorial-types") {
      return dataProvider.getListEditorial(resource, params);
    }
    if (resource == "event-type") {
      return dataProvider.getListEventType(resource, params);
    }
    if (resource == "mcdn-appnames") {
      return dataProvider.getListMCDNAppNames(resource, params);
    }

    if (resource == "hot-searchs" || resource == "kids-hot-searchs") {
      return dataProvider.getListHotSearch(resource, params);
    }
    if (resource == "import-requests") {
      return dataProvider.getListImportRequest(resource, params);
    }

    // if (resource == "mpm") {
    //   return dataProvider.getPreviewSlot(resource, params);
    // }
    // if (resource == "okbuy") {
    //   return dataProvider.getPreviewOkBuySlot(resource, params);
    // }
    // if (resource == "sponsor") {
    //   return dataProvider.getPreviewSponsorSlot(resource, params);
    // }
    // if (resource == "horserace1") {
    //   return dataProvider.getPreviewHorseRace1(resource, params);
    // }
    // if (resource == "horserace2") {
    //   return dataProvider.getPreviewHorseRace2(resource, params);
    // }
    if (resource == "episodes") {
      return dataProvider.getListEpidosePage(resource, params);
    }

    if (resource == "episodes-autocomplete") {
      return dataProvider.getListEpisodeAutoComplete(params);
    }

    if (resource == "slots") {
      return dataProvider.getSlots(resource, params);
    }

    if (resource == "programmes-autocomplete") {
      return dataProvider.searchProgrammesByKeyword(params);
    }

    if (resource == "im-canned-messages-autocomplete") {
      return dataProvider.searchCannedMessagesByKeyword(params);
    }

    if (resource == "programme-overwrite-poster-module") {
      return dataProvider.getProgrammeOverwritePosterModuleList(resource, params);
    }

    if (resource == "editorials-autocomplete") {
      if (Object.keys(params.filter).length == 0) {
        return { data: [], total: 0 };
      }
      resource = "mpm-row-schedule-types";
      originalResource = "editorials-autocomplete";
    }

    if (resource == "mpm-page-autocomplete") {
      if (Object.keys(params.filter).length == 0) {
        return { data: [], total: 0 };
      }
      resource = "mpm-pages";
      originalResource = "mpm-page-autocomplete";
    }

    if (resource == "emarsys-title") {
      originalResource = resource;
      resource = "mpm-pages";
    }

    if (resource == "page-row-paths") {
      resource = "mpm-pages";
      originalResource = "page-row-paths";
    }

    if (resource == "clips-autocomplete") {
      return dataProvider.getClipsAutocomplete(resource, params);
    }

    if (resource == "vodlives-autocomplete") {
      if (Object.keys(params.filter).length == 0) {
        return { data: [], total: 0 };
      }
      resource = "vod-lives";
    }

    if (resource == "vod-live-testing-data") {
      originalResource = resource;
      resource = "vod-lives";
      return await dataProvider.getVodLiveTestingDataList(resource, params);
    }

    if (resource == "tags-checkbox") {
      return dataProvider.getMany(resource, params);
    }

    if (resource == "channels-autocomplete") {
      if (Object.keys(params.filter).length == 0) {
        return { data: [], total: 0 };
      }
      resource = "channels";
      originalResource = "channels-autocomplete";
    }

    if (resource == "channels-autocomplete-2") {
      if (Object.keys(params.filter).length == 0) {
        return { data: [], total: 0 };
      }
      resource = "channels";
      originalResource = "channels-autocomplete-2";
    }

    if (resource == "key-value-by-quality") {
      return dataProvider.getManyKeyValue(resource, params);
    }
    if (resource == "key-value-by-event") {
      return dataProvider.getManyKeyValue(resource, params);
    }
    if (resource == "key-value-by-platform") {
      return dataProvider.getManyKeyValue(resource, params);
    }
    if (resource == "key-value-by-drm") {
      return dataProvider.getManyKeyValue(resource, params);
    }
    if (resource == "key-value-by-coding") {
      return dataProvider.getManyKeyValue(resource, params);
    }
    if (resource == "clips-type") {
      return dataProvider.getClipsType(resource, params);
    }

    console.log("getList");
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    const filterKeys = Object.keys(params.filter);
    if (filterKeys.length !== 0) {
      const filter = [];
      filterKeys.forEach((key) => {
        let expr;
        switch (typeof params.filter[key]) {
          case "string": {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = {
                $expr: {
                  $regexMatch: {
                    input: { $toString: `$${key}` },
                    regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
                    options: "i",
                  },
                },
              };
            }

            break;
          }
          default: {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = { key: params.filter[key] };
            }
          }
        }
        filter.push(expr);
      });
      queryObj.where = { $and: filter };
    }

    let json;
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/${resource}?query=${query}`;

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    let res = [];
    for (const item of json.data) {
      if (originalResource == "channels-autocomplete") {
        item.name_tc = item.name_tc + " - " + item.network_code;
        item._id = item.network_code;
      }
      if (originalResource == "channels-autocomplete-2") {
        item.channel_text = item.name_tc + " - " + item.network_code;
        item._id = item.network_code;
      }
      if (originalResource == "editorials-autocomplete") {
        item.name = item.name + " - " + item.type;
        item._id = item.type;
      }

      res.push(item);
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getList : url = " + url);
    console.log("dataProvider . getList : response : ");
    console.log(json);

    return {
      data: res,
      total: json.total,
    };
  },

  getVodLiveTestingDataList: async (resource, params) => {
    console.log("dataProvider . getVodLiveTestingDataList : params = ");
    console.log(params);

    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };
    const filterKeys = Object.keys(params.filter);
    // if (filterKeys.length !== 0) {
    const filter = [];

    // filter out status = 4
    filter.push({
      status: {
        $ne: 4,
      },
    });

    filterKeys.forEach((key) => {
      let expr;
      switch (typeof params.filter[key]) {
        case "string": {
          if (key == "tags") {
            expr = { tags: params.filter[key] };
          } else {
            expr = {
              $expr: {
                $regexMatch: {
                  input: { $toString: `$${key}` },
                  regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
                  options: "i",
                },
              },
            };
          }

          break;
        }
        default: {
          if (key == "tags") {
            expr = { tags: params.filter[key] };
          } else {
            expr = { key: params.filter[key] };
          }
        }
      }
      filter.push(expr);
    });
    queryObj.where = { $and: filter };
    // }
    let json;
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/${resource}?query=${query}`;

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    let res = [];
    for (const item of json.data) {
      res.push(item);
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getVodLiveTestingDataList : url = " + url);
    console.log("dataProvider . getVodLiveTestingDataList : response : ");
    console.log(json);

    return {
      data: res,
      total: json.total,
    };
  },

  updateChannelOrder: async (allChannelIds) => {
    console.log("dataProvider . updateChannelOrder");

    var data = {
      order: allChannelIds,
    };

    console.log(data);

    const url = `${apiUrl}/channels/update/order`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . updateChannelOrder : json = ");
    console.log(json);

    return {
      data: json,
    };
  },
  getProgrammeOverwritePosterModuleList: async (resource, params) => {
    console.log("dataProvider . getProgrammeOverwritePosterModuleList : resource = " + resource + " , params = ");
    console.log(params);

    const originalResource = resource;

    if (resource == "programme-overwrite-poster-module") {
      resource = "programme-overwrite-posters";
    }

    const { page, perPage } = params.pagination;

    const sortString = ((params) => {
      if (params.sort && params.sort.field != "order") {
        console.log("custom sort=");
        console.log(params.sort);
        const { field, order } = params.sort;
        return (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field;
      } else {
        console.log("default sort by created_at DESC");
        return "-created_at";
      }
    })(params);

    const queryObj = {
      sort: sortString,
      page: page,
      limit: perPage,
    };
    const filterKeys = Object.keys(params.filter);
    if (filterKeys.length !== 0) {
      const filter = [];
      filterKeys.forEach((key) => {
        let expr;
        switch (typeof params.filter[key]) {
          case "string": {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = {
                $expr: {
                  $regexMatch: {
                    input: { $toString: `$${key}` },
                    regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
                    options: "i",
                  },
                },
              };
            }

            break;
          }
          default: {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = { key: params.filter[key] };
            }
          }
        }
        filter.push(expr);
      });
      queryObj.where = { $and: filter };
    }
    let json;
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/${resource}?query=${query}`;

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    let res = [];
    for (const item of json.data) {
      res.push(item);
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getProgrammeOverwritePosterModuleList : url = " + url);
    console.log("dataProvider . getProgrammeOverwritePosterModuleList : response : ");
    console.log(json);

    return {
      data: res,
      total: json.total,
    };
  },

  getListImportRequest: async (resource, params) => {
    console.log("dataProvider . getListImportRequest : resource = " + resource + " , params = ");
    console.log(params);

    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };
    const filter = [{ resource: "programme" }];
    queryObj.where = { $and: filter };

    let json;
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/import-requests?query=${query}`;

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    let res = [];
    for (const item of json.data) {
      res.push(item);
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getListImportRequest : url = " + url);
    console.log("dataProvider . getListImportRequest : response : ");
    console.log(json);

    return {
      data: res,
      total: json.total,
    };
  },

  getListMCDNAppNames: async (params) => {
    console.log("dataProvider . getListMCDNAppNames : params = ");
    console.log(params);

    const queryObj = {
      page: 1,
      limit: 999999,
    };

    const filter = [{ key: "mcdn_appname" }];
    queryObj.where = { $and: filter };

    let json;
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/key-values?query=${query}`;

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    let res = [];
    for (const item of json.data) {
      res.push(item);
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getListMCDNAppNames : url = " + url);
    console.log("dataProvider . getListMCDNAppNames : response = ");
    console.log(json);

    return {
      data: res,
      total: json.total,
    };
  },

  getListEpisodeAutoComplete: async (params) => {
    console.log("dataProvider . getListEpisodeAutoComplete : params = ");
    console.log(params);

    if (Object.keys(params.filter).length == 0) {
      return { data: [], total: 0 };
    }

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    if (params.filter.programme_id) {
      if (!queryObj.where) {
        queryObj.where = {};
      }
      queryObj.where.programme_id = params.filter.programme_id;
    }
    if (params.filter.episode_no) {
      if (!queryObj.where) {
        queryObj.where = {
          $and: [
            {
              $expr: {
                $regexMatch: {
                  input: { $toString: `$episode_no` },
                  regex: ".*" + _.escapeRegExp(params.filter.episode_no) + ".*",
                  options: "i",
                },
              },
            },
          ],
        };
      }
    }

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/episodes?query=${query}`;

    console.log("dataProvider . getListEpisodeAutoComplete : url = " + url);

    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    console.log("dataProvider . getListEpisodeAutoComplete : json = ");
    console.log(json);

    for (const item of json.data) {
      const arr = [];
      if (item.name_tc) arr.push(item.name_tc);
      arr.push(item.episode_no);
      // if (item.desc_tc) arr.push(item.desc_tc.length > 15 ? item.desc_tc.substring(0, 15) + "..." : item.desc_tc);
      item.name_tc = arr.join(" - ");

      item._id = item.episode_id;
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getListEpisodeAutoComplete : result = ");
    console.log({
      data: json.data,
      total: json.total,
    });

    return {
      data: json.data,
      total: json.total,
    };
  },

  getEpisodeByEpisodeId: async (programmeId, episodeId) => {
    const queryObj = {
      where: {
        $and: [
          {
            programme_id: programmeId,
          },
          { episode_id: episodeId },
        ],
      },
    };
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/episodes?query=${query}`;

    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    var data;
    if (json.data.length > 0) {
      data = json.data[0];
    }

    return {
      data: data,
    };
  },

  getEpisodeByEpisodeNo: async (programmeId, episodeNo) => {
    const queryObj = {
      where: {
        $and: [
          {
            programme_id: programmeId,
          },
          { episode_no: episodeNo },
        ],
      },
    };
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/episodes?query=${query}`;

    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    var data;
    if (json.data.length > 0) {
      data = json.data[0];
    }

    return {
      data: data,
    };
  },

  getAllTagsList: async (params) => {
    console.log("dataProvider . getAllTagsList : params = ");
    console.log(params);

    // const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: 1,
      limit: 999999,
    };
    const filterKeys = Object.keys(params.filter);
    if (filterKeys.length !== 0) {
      const filter = [];
      filterKeys.forEach((key) => {
        let expr;
        switch (typeof params.filter[key]) {
          case "string": {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = {
                $expr: {
                  $regexMatch: {
                    input: { $toString: `$${key}` },
                    regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
                    options: "i",
                  },
                },
              };
            }

            break;
          }
          default: {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = { key: params.filter[key] };
            }
          }
        }
        filter.push(expr);
      });
      queryObj.where = { $and: filter };
    }
    let json;
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/tags?query=${query}`;

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    let res = [];
    for (const item of json.data) {
      res.push(item);
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getAllTagsList : url = " + url);
    console.log("response : ");
    console.log(json);

    return {
      data: res,
      total: json.total,
    };
  },

  getAllArtistsList: async (params) => {
    console.log("dataProvider . getAllArtistsList : params = ");
    console.log(params);

    // const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: 1,
      limit: 9999999,
    };
    const filterKeys = Object.keys(params.filter);
    if (filterKeys.length !== 0) {
      const filter = [];
      filterKeys.forEach((key) => {
        let expr;
        switch (typeof params.filter[key]) {
          case "string": {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = {
                $expr: {
                  $regexMatch: {
                    input: { $toString: `$${key}` },
                    regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
                    options: "i",
                  },
                },
              };
            }

            break;
          }
          default: {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = { key: params.filter[key] };
            }
          }
        }
        filter.push(expr);
      });
      queryObj.where = { $and: filter };
    }
    let json;
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/artists?query=${query}`;

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    let res = [];
    for (const item of json.data) {
      res.push(item);
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getAllArtistsList : url = " + url);
    console.log("response : ");
    console.log(json);

    return {
      data: res,
      total: json.total,
    };
  },

  getEpisodePreviewScheduleList: async (params) => {
    console.log("dataProvider . getEpisodePreviewScheduleList : params");
    console.log(params);

    let json;

    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + "programme_id",
      page: page,
      limit: perPage,
    };

    const url = `${apiUrl}/episode-preview-schedules?query=${JSON.stringify(queryObj)}`;

    console.log("dataProvider . getEpisodePreviewScheduleList : url");
    console.log(url);

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    console.log("dataProvider . getEpisodePreviewScheduleList : json = ");
    console.log(json);

    let res = [];
    for (const item of json.data) {
      item.id = item._id;

      if (Array.isArray(item.episode_no)) {
        item.episode_list = item.episode_no.join(",");
      } else {
        item.episode_list = item.episode_no;
      }

      if (Array.isArray(item.platform)) {
        item.platform = item.platform.join(",");
      }

      res.push(item);
    }

    console.log("dataProvider . getEpisodePreviewScheduleList : res = ");
    console.log(res);

    return {
      data: res,
      total: json.total,
    };
  },

  getSubcatFiltersList: async (resource, params) => {
    console.log("getSubcatFiltersList : params");
    console.log(params);

    let json;

    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + "category_tag_id",
      page: page,
      limit: perPage,
    };
    const filterKeys = Object.keys(params.filter);

    const filter = [];

    // use for /tags
    filter.push({ type: "category" });

    if (filterKeys.length !== 0) {
      filterKeys.forEach((key) => {
        let expr;
        switch (typeof params.filter[key]) {
          case "string": {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = {
                $expr: {
                  $regexMatch: {
                    input: { $toString: `$${key}` },
                    regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
                    options: "i",
                  },
                },
              };
            }

            break;
          }
          default: {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = { key: params.filter[key] };
            }
          }
        }
        filter.push(expr);
      });
    }

    queryObj.where = { $and: filter };

    // "/subcat-filters/category" Cannot get all cats. change to use "/tags?query={"where":{"type":"category"}}"
    // const url = `${apiUrl}/subcat-filters/category?query=${JSON.stringify(queryObj)}`;
    const url = `${apiUrl}/tags?query=${JSON.stringify(queryObj)}`;

    console.log("getSubcatFiltersList : url");
    console.log(url);

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    console.log("getSubcatFiltersList : json = ");
    console.log(json);

    let res = [];
    for (const item of json.data) {
      // item.id = item.category_tag_id;
      item.id = item.tag_id;
      res.push(item);
      // eslint-disable-next-line
      // delete Object.assign(item, { ["id"]: item["category_tag_id"] })["category_tag_id"];
    }

    console.log("getSubcatFiltersList : res = ");
    console.log(res);

    return {
      data: res,
      total: json.total,
    };
  },

  getMenuTreePreview: async (resource, params) => {
    console.log("getMenuTreePreview");

    const queryObj = {
      where: {
        profile_class: params.filter.profile_class,
      },
    };

    const query = JSON.stringify(queryObj);

    //Kids requires both general & kids
    var url = `${apiUrl}/menu-tree/preview`;
    if (params.filter.profile_class == "general") {
      url += `?profile_class=${params.filter.profile_class}`;
    }

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    return {
      data: convert_IdToId(json),
      total: json.length,
    };
  },
  getListEpidosePage: async (resource, params) => {
    console.log("getListEpidosePage");
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    console.log(params);
    if (params.filter.programme_id) {
      queryObj.where = { programme_id: parseInt(params.filter.programme_id) };
    }
    if (params.filter.episode_id) {
      queryObj.where = { episode_id: params.filter.episode_id };
    }

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/episodes?query=${query}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    for (const item of json.data) {
      if (Array.isArray(item["is_preview"])) {
        item["is_preview"] = translatePlatformArray(item["is_preview"]);
      } else {
        item["is_preview"] = "n/a";
      }

      // eslint-disable-next-line
      item.nameWithEpisodeNo = item.name_tc + " - " + item.episode_no;
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }
    return {
      data: json.data,
      total: json.total,
    };
  },
  getListCategories: async (resource, params) => {
    console.log("getListCategories");
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const queryObj = {};
    queryObj.where = { enabled: "true" };

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/${resource}?query=${query}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    const data = [];

    for (const item of json.data) {
      // eslint-disable-next-line
      data.push(item.categories);
    }
    for (const i of data[0]) {
      i.id = i.category_id;
      delete Object.assign(i, { ["id"]: i["category_id"] })["category_id"];
    }
    console.log(data[0].length);
    return {
      data: data[0],
      total: data[0].length,
    };
  },

  getListCategoriesLevel: async (resource, params) => {
    console.log("getListCategoriesLevel");
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const queryObj = {};
    queryObj.where = { parent_id: params.filter.parent_id };

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/${resource}?level=2&query=${query}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    const data = [];

    for (const i of json.data) {
      i.id = i.category_id;
      delete Object.assign(i, { ["id"]: i["category_id"] })["category_id"];
    }

    return {
      data: json.data,
      total: json.data.length,
    };
  },

  getListKeyValues: async (resource, params) => {
    console.log("getListKeyValue");

    const queryObj = {
      sort: "order",
      page: 1,
      limit: 10,
    };
    console.log(params);
    if (params.filter.key) {
      queryObj.where = { key: params.filter.key };
    }

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/key-values?query=${query}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    //console.log(json);
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }
    return {
      data: json.data,
      total: json.total,
    };
  },

  getListEventType: async (resource, params) => {
    console.log("getListEventType");

    const queryObj = {
      sort: "order",
      page: 1,
      limit: 10,
    };
    console.log(params);
    queryObj.where = { key: "event_type" };

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/key-values?query=${query}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    //console.log(json);
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }
    return {
      data: json.data,
      total: json.total,
    };
  },

  getListNotifications: async (resource, params) => {
    console.log("dataProvider . getListNotification : params = ");
    console.log(params);

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };
    const start_time = params.filter.start_time;
    console.log(start_time);
    let end_time = params.filter.end_time;

    const filterKeys = Object.keys(params.filter);
    if (filterKeys.length !== 0) {
      const filter = [];
      filterKeys.forEach((key) => {
        delete params.filter.start_time;
        delete params.filter.end_time;
        let expr;
        switch (typeof params.filter[key]) {
          case "string": {
            expr = {
              $expr: {
                $regexMatch: {
                  input: { $toString: `$${key}` },
                  regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
                  options: "i",
                },
              },
            };

            break;
          }
          default: {
            expr = { key: params.filter[key] };
          }
        }
        filter.push(expr);
      });
      if (start_time && !end_time) {
        const d = { scheduled_push_start_date: { $gte: start_time } };
        filter.push(d);
      }
      if (!start_time && end_time) {
        const e = { scheduled_push_start_date: { $lte: end_time } };
        filter.push(e);
      }
      if (start_time && end_time) {
        const b = {
          scheduled_push_start_date: { $gte: start_time, $lte: end_time },
        };
        filter.push(b);
      }
      queryObj.where = { $and: filter };
    }

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/${resource}?query=${query}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    //console.log(json);
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getListNotification : json = ");
    console.log(json);

    return {
      data: json.data,
      total: json.total,
    };
  },

  getListEvent: async (resource, params) => {
    console.log("getListEvebnt");
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };
    const start_time = params.filter.start_time;
    let end_time = params.filter.end_time;

    const filterKeys = Object.keys(params.filter);
    if (filterKeys.length !== 0) {
      const filter = [];
      filterKeys.forEach((key) => {
        delete params.filter.start_time;
        delete params.filter.end_time;
        let expr;
        switch (typeof params.filter[key]) {
          case "string": {
            expr = {
              $expr: {
                $regexMatch: {
                  input: { $toString: `$${key}` },
                  regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
                  options: "i",
                },
              },
            };

            break;
          }
          default: {
            expr = { key: params.filter[key] };
          }
        }
        filter.push(expr);
      });
      if (start_time && !end_time) {
        const d = { start_time: { $gte: start_time } };
        filter.push(d);
      }
      if (!start_time && end_time) {
        const e = { end_time: { $lte: end_time } };
        filter.push(e);
      }
      if (start_time && end_time) {
        const b = {
          start_time: { $gte: start_time },
          end_time: { $lte: end_time },
        };
        filter.push(b);
      }
      queryObj.where = { $and: filter };
    }
    console.log(queryObj);
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/${resource}?query=${query}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    //console.log(json);
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },

  getListProgramme: async (resource, params) => {
    console.log("getListProgramme");

    const queryObj = {
      sort: "-modified_at",
      page: 1,
      limit: 10,
    };
    queryObj.where = { programme_id: parseInt(params.programme_id) };
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/${resource}?query=${query}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    //console.log(json);
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }
    return {
      data: json.data,
      total: json.total,
    };
  },

  getListEditorial: async (resource, params) => {
    console.log("dataProvider . getListEditorial");

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    var filters = [];

    const filterKeys = Object.keys(params.filter);
    if (filterKeys.includes("page")) {
      const filterPageName = params.filter["page"];
      const url = `${apiUrl}/mpm-pages/row?type=editorial_pick&filterPageName=${filterPageName}`;
      const { json } = await httpClient(url, {
        headers: await getAuthHeaders(),
      });

      var editorialTypes = [];
      for (const item of json.data) {
        var editorialType = item["ref"].split("/")[1];
        editorialTypes.push(editorialType);
      }

      filters.push({
        type: {
          $in: editorialTypes,
        },
      });

      //Remove page from filterKeys
      const index = filterKeys.indexOf("page");
      if (index > -1) {
        filterKeys.splice(index, 1); // 2nd parameter means remove one item only
      }
    }

    if (filterKeys.length !== 0) {
      filterKeys.forEach((key) => {
        let expr = {
          $expr: {
            $regexMatch: {
              input: { $toString: `$${key}` },
              regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
              options: "i",
            },
          },
        };

        filters.push(expr);
      });
    }

    if (filters.length > 0) {
      queryObj.where = { $and: filters };
    }

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/mpm-row-schedule-types?query=${query}`;
    console.log("dataProvider . getListEditorial : url = ");
    console.log(url);

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    //console.log(json);
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getListEditorial : json = ");
    console.log(json);

    return {
      data: json.data,
      total: json.total,
    };
  },

  getListEpidose: async (resource, params) => {
    console.log("getListEpidose");

    const queryObj = {
      sort: "-modified_at",
      page: 1,
      limit: 100,
    };
    console.log(params);
    if (params.filter.programme_id) {
      queryObj.where = { programme_id: parseInt(params.filter.programme_id) };
    }
    if (params.filter.episode_id) {
      queryObj.where = { episode_id: params.filter.episode_id };
    }

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/episodes?query=${query}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    //console.log(json);
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }
    return {
      data: json.data,
      total: json.total,
    };
  },
  getListRowPage: async (resource, params) => {
    console.log("getListRowPage");

    const queryObj = {
      sort: "-modified_at",
      page: 1,
      limit: 500,
    };

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/mpm-pages/row?type=editorial_pick`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    //console.log(json);
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }
    return {
      data: json.data,
      total: json.total,
    };
  },

  getOneSlot: async (resource, params) => {
    console.log("getOneSlot : params id = " + params.id);
    const url = `${apiUrl}/mpm-row-schedules/${params.id}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    console.log("getOneSlot : json = ");
    console.log(json);

    // backup posters
    if (json.portrait_poster) {
      json.portrait_poster_custom = json.portrait_poster;
    }
    if (json.landscape_poster) {
      json.landscape_poster_custom = json.landscape_poster;
    }

    const start_time = HKTime(json.start_time);
    delete json.start_time;
    json.start_time = start_time;
    //Note: Some data are Uppercase
    json.content_type = json.content_type.toLowerCase();
    json.region = convertJsonToRegion(json);

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  getOneEditorial: async (resource, params) => {
    console.log("getOneEditorial");
    const url = `${apiUrl}/mpm-row-schedule-types/${params.id}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    return {
      data: json,
    };
  },
  getOneCategory: async (resource, params) => {
    console.log("getOneCategory");
    const url = `${apiUrl}/categories?query={"where":{"category_id":${params.id}}}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    console.log(json);
    for (const item of json.data) {
      if (item.categories.category_id == params.id) {
        item.categories.id = item.categories.category_id;
        return {
          data: item.categories,
        };
        break;
      }
    }
  },

  emergencyStartEndMessageList: async (chatroomId, cannedMessageListId) => {
    console.log("emergencyStartEndMessageList");

    const data = {
      _id: cannedMessageListId,
    };
    const url = `${apiUrl}/im-rooms/${chatroomId}/emergency-start-message-list`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    return;
  },

  emergencyEndMessageList: async (chatroomId, cannedMessageListId) => {
    console.log("emergencyEndMessageList");

    const data = {
      _id: cannedMessageListId,
    };
    const url = `${apiUrl}/im-rooms/${chatroomId}/emergency-end-message-list`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    return;
  },

  emergencyStartEndChatroom: async (chatroomId, startNow, endNow) => {
    console.log("emergencyStartEndChatroom");

    const data = {
      start_now: startNow,
      end_now: endNow,
    };
    const url = `${apiUrl}/im-rooms/${chatroomId}/emergency-start-end`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    return;
  },

  getIMRoomProducts: async (productIds) => {
    console.log("getIMRoomProducts");

    var queryStrings = [];
    for (const productId of productIds) {
      queryStrings.push(`products=${productId}`);
    }

    const url = `${apiUrl}/im-room-products?${queryStrings.join("&")}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    var data = null;
    if (json) {
      //Convert _id to id
      data = convert_IdToId(json);

      if (data) {
        for (const product of data.products) {
          product.open_url = product.is_in_app_url ? "in_app" : "out_app";
          product.onshelf_start_times = [];
          product.onshelf_end_times = [];
          product.live_promote_start_times = [];
          product.live_promote_end_times = [];

          for (const actionLog of product.live_promote_action_log) {
            const d = moment.tz(actionLog.modified_at, "Asia/Hong_Kong");
            const r = d.format("YYYY-MM-DD HH:mm");

            if (actionLog.is_live_promote) {
              product.live_promote_start_times.push(r);
            } else {
              product.live_promote_end_times.push(r);
            }
          }

          for (const actionLog of product.on_shelf_action_log) {
            const d = moment.tz(actionLog.modified_at, "Asia/Hong_Kong");
            const r = d.format("YYYY-MM-DD HH:mm");

            if (actionLog.is_onshelf) {
              product.onshelf_start_times.push(r);
            } else {
              product.onshelf_end_times.push(r);
            }
          }
        }

        //Follow the order of productIds
        data.products = productIds.map((productId) => data.products.find((product) => product.id == productId));
      }
    }

    return data.products;
  },

  getOneIMRooms: async (resource, params) => {
    console.log("getOneIMRooms");
    const url = `${apiUrl}/im-rooms/${params.id}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    var data = null;
    if (json) {
      //Convert _id to id
      data = convert_IdToId(json);

      //Get room_summaries if any
      if (data.room_summaries && Object.keys(data.room_summaries).length > 0) {
        const roomSummary = data.room_summaries;

        //Canned Messages
        // var cannedMessageListSummaries = {};
        // for (const cannedMessageStat of roomSummary.canned_message_stats) {
        //   // const cannedMessageListId = cannedMessageStat.canned_message_list_id;
        //   // const cannedMessageId = cannedMessageStat.canned_message_id;

        //   // const cannedMessageList = data.canned_message_list.find((e) => e.id == cannedMessageListId);
        //   // const cannedMessage = cannedMessageList?.canned_messages.find((e) => e.id == cannedMessageId);

        //   if (!cannedMessageListSummaries[cannedMessageListId]) {
        //     cannedMessageListSummaries[cannedMessageListId] = {
        //       id: cannedMessageListId,
        //       name: cannedMessageList?.name ?? "-",
        //       canned_messages: [],
        //     };
        //   }

        //   cannedMessageListSummaries[cannedMessageListId]["canned_messages"].push({
        //     id: cannedMessageId,
        //     text: cannedMessage?.text ?? "-",
        //     count: cannedMessageStat.count,
        //   });
        // }

        // data.canned_message_list_summaries = Object.values(cannedMessageListSummaries);

        //Icons
        var iconSummaries = [];
        for (const iconStat of roomSummary.icon_stats) {
          const iconId = iconStat.icon_id;

          if (data.icon_list && data.icon_list.length > 0) {
            const icon = data.icon_list[0].icons.find((e) => e.id == iconId);

            if (icon) {
              iconSummaries.push({
                // id: iconId,
                // image_url: iconStat.icon,
                image_url: icon.image_url,
                count: iconStat.count,
              });
            }
          }
        }
        data.icon_summaries = iconSummaries;
      }

      //Add status for convinient
      data.status = getIMStatus(data.start_time, data.end_time);

      if (data.mode) {
        if (data.mode == "default") {
          data.mode = [];
        } else {
          data.mode = [data.mode];
        }
      }

      //Each im-room should have only one icon_list, Move icon_list to anothe variable
      if (data.icon_list && data.icon_list.length > 0) {
        var iconList = data.icon_list[0];

        data["icons"] = iconList.icons;
        data["icon_list_id"] = iconList.id;
      }

      //Add canned_mesasge status for convinient
      if (data.canned_message_list) {
        for (const item of data.canned_message_list) {
          item.status = getIMStatus(item.start_time, item.end_time);
          item.voting_enabled = item.list_type == "vote";
        }
      }

      if (data.products) {
        for (const item of data.products) {
          item.open_url = item.is_in_app_url ? "in_app" : "out_app";
        }

        const productIds = data.products.map((product) => product.id);
        const products = await dataProvider.getIMRoomProducts(productIds);
        data.products = products; //Replace whole products field
      }

      //Backward compatible to type = String
      if (Array.isArray(data.channel_no)) {
        data.channel_no = data.channel_no.join(",");
      }

      //Move stats_config out 1 level
      data = { ...data, ...data["stats_config"] };
    }

    return {
      data: data,
    };
  },

  getOneIMRoomProducts: async (productId) => {
    console.log("getOneIMRoomProducts");
    const url = `${apiUrl}/im-room-products/${productId}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    var data = null;
    if (json) {
      //Convert _id to id
      data = convert_IdToId(json);

      if (data) {
        data.open_url = data.is_in_app_url ? "in_app" : "out_app";
        data.onshelf_start_times = [];
        data.onshelf_end_times = [];
        data.live_promote_start_times = [];
        data.live_promote_end_times = [];

        for (const actionLog of data.live_promote_action_log) {
          const d = moment.tz(actionLog.modified_at, "Asia/Hong_Kong");
          const r = d.format("YYYY-MM-DD HH:mm");

          if (actionLog.is_live_promote) {
            data.live_promote_start_times.push(r);
          } else {
            data.live_promote_end_times.push(r);
          }
        }

        for (const actionLog of data.on_shelf_action_log) {
          const d = moment.tz(actionLog.modified_at, "Asia/Hong_Kong");
          const r = d.format("YYYY-MM-DD HH:mm");

          if (actionLog.is_onshelf) {
            data.onshelf_start_times.push(r);
          } else {
            data.onshelf_end_times.push(r);
          }
        }
      }
    }

    return {
      data: data,
    };
  },

  getOne: async (resource, params) => {
    console.log("getOne : resource = " + resource + " params = ");
    console.log(params);

    if (resource == "hot-searchs" || resource == "kids-hot-searchs") {
      resource = "hot-searchs";
    }

    if (resource == "profile-icons" || resource == "kids-profile-icons") {
      return dataProvider.getOneProfileIcons(resource, params);
    }

    if (resource == "im-rooms") {
      return dataProvider.getOneIMRooms(resource, params);
    }

    if (resource == "push-notifications") {
      return dataProvider.getOneNotification(resource, params);
    }
    if (resource == "subcat-filters") {
      return dataProvider.getOneSubcatFilters(resource, params);
    }
    if (resource == "subcat-filter-overwrite-list") {
      return dataProvider.getOneSubcatFilterOverwriteList(resource, params);
    }
    if (resource == "subcat-filter-overwrite-create") {
      return dataProvider.getOneSubcatFilterOverwriteCreate(resource, params);
    }
    if (resource == "subcat-filter-overwrite-edit") {
      return dataProvider.getOneSubcatFilterOverwriteEdit(resource, params);
    }

    if (resource == "slots") {
      return dataProvider.getOneSlot(resource, params);
    }

    if (resource == "categories") {
      return dataProvider.getOneCategory(resource, params);
    }

    if (resource == "event-type") {
      return dataProvider.getOneEventType(resource, params);
    }
    if (resource == "mcdn-appname") {
      return dataProvider.getOneMcdnAppName(resource, params);
    }

    if (resource == "channels") {
      return dataProvider.getOneChannel(resource, params);
    }

    if (resource == "programmes") {
      return dataProvider.getOneProgramme(resource, params);
    }

    if (resource == "episodes") {
      return dataProvider.getOneEpisode(resource, params);
    }

    if (resource == "editorial-types") {
      return dataProvider.getOneEditorial(resource, params);
    }
    if (resource == "mpm-rows") {
      return dataProvider.getOneMpmRows(resource, params);
    }

    if (resource == "mpm-home-page" || resource == "kids-mpm-home-page") {
      resource = "mpm-pages";
    }

    if (resource == "emarsys-title") {
      resource = "mpm-pages";
    }

    if (resource == "page-row-paths") {
      resource = "mpm-pages";
    }

    if (resource == "programme-overwrite-poster-module") {
      resource = "programme-overwrite-posters";
    }

    if (resource == "vod-live-testing-data") {
      resource = "vod-lives";
    }

    const url = `${apiUrl}/${resource}/${params.id}`;
    console.log("dataProvider . getOne : url = " + url);

    var { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    console.log("dataProvider . getOne : json = ");
    console.log(json);

    // eslint-disable-next-line
    if (resource == "mpm-rows") {
      //Because ref is keyword, we need to rename it
      delete Object.assign(json, { ["ref_x"]: json["ref"] })["ref"];

      if (json["type"] == "recom_personalised") {
        json["type"] = "recommendation";
        json["recommendation_type"] = "recom_personalised";
      } else if (json["type"] == "recom_trendy") {
        json["type"] = "recommendation";
        json["recommendation_type"] = "recom_trendy";
      } else if (json["type"] == "recom_universal") {
        json["type"] = "recommendation";
        json["recommendation_type"] = "recom_universal";
      } else if (json["type"] == "recom_free_preview") {
        json["type"] = "recommendation";
        json["recommendation_type"] = "recom_free_preview";
      } else if (json["type"] == "programme_list") {
        var { data } = await dataProvider.getMany("tags", {
          ids: json["ref_x"],
        });
        json["tags"] = data.map((tag) => ({ tag_type: tag.type, tag_value: tag.tag_id }));
      } else if (json["type"] == "editorial_pick") {
        if (json["ref_x"].startsWith("events")) {
          json["type"] = "event";
        }
      } else if (json["type"] == "latest") {
        if (json["filters"] && json["filters"].length > 0) {
          var tagIds = json["filters"][0]["tags"];
          var { data } = await dataProvider.getMany("tags", {
            ids: tagIds,
          });
          json["tags"] = data.map((tag) => ({ tag_type: tag.type, tag_value: tag.tag_id }));
        }
      } else if (json["type"] == "top_sv") {
        var { data } = await dataProvider.getMany("tags", {
          ids: json["ref_x"],
        });
        json["tags"] = data.map((tag) => ({ tag_type: tag.type, tag_value: tag.tag_id }));
      } else if (json["type"] == "clip_list") {
        var { data } = await dataProvider.getMany("tags", {
          ids: json["ref_x"],
        });
        json["tags"] = data.map((tag) => ({ tag_type: tag.type, tag_value: tag.tag_id }));
      }
    } else if (resource == "mpm-pages") {
      json.made_for_kid = false;
      if (json.profile_class.includes("kids")) {
        json.made_for_kid = true;
      }

      json.template = [];
      if (json.is_olympic) {
        json.template.push("is_olympic");
      }
    }

    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . getOne : result = ");
    console.log(json);

    return {
      data: json,
    };
  },

  getOneSubcatFilters: async (resource, params) => {
    console.log("getOneSubcatFilters");

    return {
      data: {
        id: parseInt(params.id),
        message: "i have no data about getOneSubcatFilters",
      },
    };
  },

  getOneSubcatFilterOverwriteList: async (resource, params) => {
    console.log("dataProvider . getOneSubcatFilterOverwriteList : tag_id = " + params.id);

    const tag = await dataProvider.getTags([params.id]);

    console.log("dataProvider . getOneSubcatFilterOverwriteList : tag = ");
    console.log(tag);

    const queryObj = {
      sort: "order",
      where: {
        category_tag_id: parseInt(params.id),
      },
    };

    const url = `${apiUrl}/subcat-filter-overwrite?query=` + JSON.stringify(queryObj);
    console.log("dataProvider . getOneSubcatFilterOverwriteList : url = " + url);

    var { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json.data.map((item) => {
      item.id = item._id;
    });
    json.id = params.id;
    json.tag = tag.data[0];
    console.log("dataProvider . getOneSubcatFilterOverwriteList : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  getOneSubcatFilterOverwriteCreate: async (resource, params) => {
    console.log("dataProvider . getOneSubcatFilterOverwriteCreate : tag_id = " + params.id);

    const tag = await dataProvider.getTags([params.id]);

    console.log("dataProvider . getOneSubcatFilterOverwriteCreate : tag = ");
    console.log(tag);

    const json = {
      id: params.id,
      tag: tag.data[0],
    };

    console.log("dataProvider . getOneSubcatFilterOverwriteCreate : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  getOneSubcatFilterOverwriteEdit: async (resource, params) => {
    // console.log('dataProvider . getOneSubcatFilterOverwriteEdit : tag_id = ' + params.id);

    // const tag = await dataProvider.getTags([params.id]);

    // console.log('dataProvider . getOneSubcatFilterOverwriteEdit : tag = ');
    // console.log(tag);

    const url = `${apiUrl}/subcat-filter-overwrite/${params.id}`;
    // console.log(url);
    var { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    // const json = {
    //   id: params.id,
    //   tag: tag.data[0],
    // };
    // json.tag = tag.data[0];

    console.log("dataProvider . getOneSubcatFilterOverwriteEdit : json = ");
    console.log(json);

    if (json._id) json.id = json._id;

    return {
      data: json,
    };
  },

  getOneMpmRows: async (resource, params) => {
    console.log("getOneMpmRows : params = ");
    console.log(params);

    const url = `${apiUrl}/${resource}/${params.id}`;
    // console.log(url);
    var { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    console.log("getOneMpmRows : json = ");
    console.log(json);
    // eslint-disable-next-line

    //Because ref is keyword, we need to rename it

    // if(json["ref"]){
    json.region = convertJsonToRegion(json);
    delete Object.assign(json, { ["ref_x"]: json["ref"] })["ref"];
    // }

    if (json["type"] == "recom_personalised") {
      json["type"] = "recommendation";
      json["recommendation_type"] = "recom_personalised";
    } else if (json["type"] == "recom_trendy") {
      json["type"] = "recommendation";
      json["recommendation_type"] = "recom_trendy";
    } else if (json["type"] == "recom_universal") {
      json["type"] = "recommendation";
      json["recommendation_type"] = "recom_universal";
    } else if (json["type"] == "recom_free_preview") {
      json["type"] = "recommendation";
      json["recommendation_type"] = "recom_free_preview";
    } else if (json["type"] == "programme_list") {
      var { data } = await dataProvider.getMany("tags", {
        ids: json["ref_x"],
      });
      json["tags"] = data.map((tag) => ({ tag_type: tag.type, tag_value: tag.tag_id }));
    } else if (json["type"] == "editorial_pick") {
      if (json["ref_x"].startsWith("events")) {
        json["type"] = "event";
      }
    } else if (json["type"] == "latest") {
      if (json["filters"] && json["filters"].length > 0) {
        var tagIds = json["filters"][0]["tags"];
        var { data } = await dataProvider.getMany("tags", {
          ids: tagIds,
        });
        json["tags"] = data.map((tag) => ({ tag_type: tag.type, tag_value: tag.tag_id }));
      }
    } else if (json["type"] == "top_sv") {
      var { data } = await dataProvider.getMany("tags", {
        ids: json["ref_x"],
      });
      json["tags"] = data.map((tag) => ({ tag_type: tag.type, tag_value: tag.tag_id }));
    } else if (json["type"] == "clip_list") {
      var { data } = await dataProvider.getMany("tags", {
        ids: json["ref_x"],
      });
      json["tags"] = data.map((tag) => ({ tag_type: tag.type, tag_value: tag.tag_id }));
    }

    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    // also get its parent : mpm-pages
    const pagesResult = await dataProvider._getMpmPagesByRowId(json.id);

    console.log("getOneMpmRows: pagesResult = ");
    console.log(pagesResult);

    if (pagesResult.data.length > 0) {
      json.page = pagesResult.data[0];
    }

    // if (json.template) {
    //   json.template_1 = json.template;
    //   json.template_2 = json.template;
    //   json.template_3 = json.template;
    // }

    console.log("getOneMpmRows: result json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  _getMpmPagesByRowId: async (id) => {
    console.log("_getMpmPagesByRowId: id = " + id);

    const queryObj = {
      where: {
        rows: id,
      },
    };

    const url = `${apiUrl}/mpm-pages?query=` + JSON.stringify(queryObj);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    console.log("_getMpmPagesByRowId: result json = ");
    console.log(json);

    return json;
  },

  getOneMenuTree: async (id) => {
    console.log("getOneMenuTree : id = " + id);

    const url = `${apiUrl}/menu-tree/${id}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    json.menu_tree = json.menu_tree.map((item, index) => {
      item.id = "lv1_" + index;
      item.region = convertJsonToRegion(item);

      if (item.categories && item.categories.length) {
        item.categories = item.categories.map((item2, index2) => {
          item2.id = "lv2_" + index2;
          item2.region = convertJsonToRegion(item2);

          return item2;
        });
      }
      return item;
    });

    console.log("getOneMenuTree : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  getOneEpisodeProgramme: async (resource, params) => {
    console.log("getOneEpisodeProgramme");
    const queryObj = {
      sort: "-modified_at",
      page: 1,
      limit: 10,
    };
    const filterKeys = Object.keys(params.filter);
    if (filterKeys.length !== 0) {
      const filter = [];
      filterKeys.forEach((key) => {
        delete params.filter.start_time;
        delete params.filter.end_time;
        let expr;
        switch (typeof params.filter[key]) {
          case "string": {
            expr = {
              $expr: {
                $regexMatch: {
                  input: { $toString: `$${key}` },
                  regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
                  options: "i",
                },
              },
            };

            break;
          }
          default: {
            expr = { key: params.filter[key] };
          }
        }
        filter.push(expr);
      });
      queryObj.where = { $and: filter };
    }
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/programmes?query=${query}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    //console.log(json);
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }
    return {
      data: json.data,
      total: json.total,
    };
  },

  getOneEventType: async (resource, params) => {
    console.log("getOneEventType");

    const url = `${apiUrl}/key-values/${params.id}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    let data = [];
    console.log(json);
    for (const value of json.values) {
      data.push({ name: value });
    }
    json.values = data;
    return {
      data: json,
    };
  },

  getOneMcdnAppName: (resource, params) => {
    console.log("getOneMcdnAppName : id = " + params.id);

    return dataProvider.getOneEventType(resource, params);
  },

  getOneVodLive: async (resource, params) => {
    console.log("getOneVodLive");

    const url = `${apiUrl}/${resource}/${params.id}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  getOneEditorialTypes: async (resource, params) => {
    console.log("getOneEditorialTypes");

    const url = `${apiUrl}/${resource}`;
    console.log(url);
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  getOneChannel: async (resource, params) => {
    console.log("getOneChannel : params = ");
    console.log(params);

    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    const start_time = HKTime(json.start_time);
    const end_time = HKTime(json.end_time);
    delete json.start_time;
    delete json.end_time;
    json.start_time = start_time;
    json.end_time = end_time;
    json.region = convertJsonToRegion(json);

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("getOneChannel : json = ");
    console.log(json);

    // get the hd_sd by the response
    const profilesMapping = await dataProvider.getManyProfilesMapping("profiles-mapping", {
      bitrate_profile_id: json.bitrate_profile,
      coding_profile_id: json.coding_profile,
      drm_profile_id: json.drm_profile,
    });

    console.log("getOneChannel : profilesMapping = ");
    console.log(profilesMapping);

    // TODO : decide when result has more than one
    if (profilesMapping.data.data.length > 1) {
      json.hd_sd = profilesMapping.data.data[0]._id;
    }
    if (profilesMapping.data.data.length == 1) {
      json.hd_sd = profilesMapping.data.data[0]._id;
    }

    json.made_for_kid = false;
    if (json.profile_class.includes("kids")) {
      json.made_for_kid = true;
    }

    return {
      data: json,
    };
  },

  getOneProfileIcons: async (resource, params) => {
    console.log("dataProvider . getOneProfileIcons : params = ");
    console.log(params);

    const url = `${apiUrl}/profile-icons/${params.id}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    return {
      data: convert_IdToId(json),
    };
  },

  getOneNotification: async (resource, params) => {
    console.log("dataProvider . getOneNotification : params = ");
    console.log(params);

    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    const scheduled_push_start_date = HKTime(json.scheduled_push_start_date);
    delete json.scheduled_push_start_date;
    json.scheduled_push_start_date = scheduled_push_start_date;

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    /*
    Make the "prefill_url_content"
    Example:
      programme:
        https://qa-www.mytvsuper.com/tc/programme/hackenleespecial2017_111837/
      episode:
        https://qa-www.mytvsuper.com/tc/programme/enfusionlive_110508/e/302551/    
      live_channel:
        https://qa-www.mytvsuper.com/tc/live/604/
    */

    if (json.destination_url.startsWith(mytvsuperUrl + "/")) {
      var prefill_url_content = "";

      //Backward compaitable, old version does not have "tc"
      const slug = json.destination_url.replace(mytvsuperUrl + "/", "").split("/");
      if (slug[0] == "tc") {
        slug.shift();
      }

      //destination_url can be entered manually, so, prefill_url_content can be empty
      if (slug[0] && slug[0] == "live") {
        prefill_url_content = "live_channel";
      }
      if (slug[0] && slug[0] == "programme") {
        if (slug[2] && slug[2] == "e") {
          prefill_url_content = "episode";
        }
        prefill_url_content = "programme";
      }

      json.prefill_url_content = prefill_url_content;

      var title, slug2, programmeId;
      switch (prefill_url_content) {
        case "programme":
          if (!slug[1]) {
            break;
          }

          title = slug[1];
          slug2 = title.split("_");
          programmeId = slug2[slug2.length - 1];

          const p = await dataProvider.getProgrammeByProgrammeId(programmeId);
          console.log("dataProvider.getOneNotification : programme = ");
          console.log(p);
          json.title_tc = p.data.name_tc;
          json.title_en = p.data.name_en;
          break;

        case "episode":
          if (!slug[3]) {
            break;
          }

          title = slug[1];
          slug2 = title.split("_");
          programmeId = slug2[slug2.length - 1];
          const episodeId = slug[3];
          const e = await dataProvider.getEpisodeByEpisodeId(programmeId, episodeId);
          console.log("dataProvider.getOneNotification : episode = ");
          console.log(e);
          json.title_tc = e.data.name_tc;
          json.title_en = e.data.name_en;
          break;

        case "live_channel":
          // no api to do this
          break;
        default:
          break;
      }
    }

    console.log("dataProvider . getOneNotification : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  getOneProgramme: async (resource, params) => {
    console.log("getOneProgramme : id = " + params.id);

    const url = `${apiUrl}/${resource}/${params.id}`;
    console.log("getOneProgramme : url = ");
    console.log(url);

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    console.log("getOneProgramme : json = ");
    console.log(json);

    const free_start_time = HKTime(json.free_start_time);
    const free_end_time = HKTime(json.free_end_time);
    const pay_start_time = HKTime(json.pay_start_time);
    const pay_end_time = HKTime(json.pay_end_time);
    delete json.free_start_time;
    delete json.free_end_time;
    delete json.pay_start_time;
    delete json.pay_end_time;
    json.free_start_time = free_start_time;
    json.free_end_time = free_end_time;
    json.pay_start_time = pay_start_time;
    json.pay_end_time = pay_end_time;
    json.region = convertJsonToRegion(json);
    json.mobile_early_release = false;
    for (const pt of json.platform_time) {
      if (pt.type == "early_release") {
        json.mobile_early_release = true;
        json.mobile_early_release_end_time = HKTime(pt.end_time);
      }

      if (pt.type == "limited_platform") {
        json.limited_platform = pt.platform.join(", ");
      }
    }

    // get programme/:id/info
    await dataProvider.addProgrammeInfoById(json, params.id);

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  getOneEpisode: async (resource, params) => {
    console.log("getOneEpisode : id = " + params.id);

    const url = `${apiUrl}/${resource}/${params.id}`;
    console.log("getOneProgramme : url = ");
    console.log(url);

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    console.log("getOneEpisode : json = ");
    console.log(json);

    if (Array.isArray(json["is_preview"])) {
      json["is_preview"] = translatePlatformArray(json["is_preview"]);
    } else {
      json["is_preview"] = "n/a";
    }

    for (const pt of json.platform_time) {
      if (pt.type == "early_release") {
        json.app_web_first_start_time = HKTime(pt.start_time);
        json.app_web_first_end_time = HKTime(pt.end_time);
      }

      if (pt.type == "free") {
        json.app_web_free_start_time = HKTime(pt.start_time);
        json.app_web_free_end_time = HKTime(pt.end_time);
      }
    }

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    return {
      data: json,
    };
  },
  addProgrammeInfoById: async (jsonData, programmeId) => {
    const url2 = `${apiUrl}/programmes/${programmeId}/info`;
    const result = await httpClient(url2, {
      headers: await getAuthHeaders(),
    });
    const json2 = result.json;
    console.log("getOneProgramme : url2 = ");
    console.log(url2);
    console.log("getOneProgramme : result = ");
    console.log(result);
    console.log("getOneProgramme : json2 = ");
    console.log(json2);
    // get default_start_time & default_end_time
    if (json2) {
      if (json2.default_start_time) {
        jsonData.default_start_time = json2.default_start_time;
      }
      if (json2.default_end_time) {
        jsonData.default_end_time = json2.default_end_time;
      }
    }
  },

  getMpmHomepage: async (profileClass) => {
    console.log("getMpmHomepage");

    const url = `${apiUrl}/mpm-pages?query=` + JSON.stringify({ where: { is_homepage: true, profile_class: profileClass } });
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    var homepage = json.data[0];

    delete Object.assign(homepage, { ["id"]: homepage["_id"] })["_id"];
    return {
      data: homepage,
    };
  },

  getMany: async (resource, params) => {
    console.log("getMany : resource = " + resource + " , params = ");
    console.log(params);
    var query = "";
    var originalResource = resource;

    if (resource == "mpm-rows") {
      return dataProvider.getManyMpmRows(resource, params);
    }
    if (resource == "tags-autocomplete") {
      return dataProvider.getManyTagsAutocomplete(resource, params);
    }
    if (resource == "im-canned-messages-autocomplete") {
      return dataProvider.getManyIMCannedMessages(resource, params);
    }

    if (resource == "mpm-row-tag-autocomplete") {
      resource = "tags";
      query = JSON.stringify({ where: { tag_id: { $in: params.ids } } });
    }

    if (resource == "programmes-for-import-requests") {
      return dataProvider.getManyProgrammesForImportRequests(resource, params);
    }
    if (resource == "key-value-by-quality") {
      return dataProvider.getManyKeyValue(resource, params);
    }
    if (resource == "key-value-by-platform") {
      return dataProvider.getManyKeyValue(resource, params);
    }
    if (resource == "clips-type") {
      return dataProvider.getClipsType(resource, params);
    }
    if (resource == "key-value-by-drm") {
      return dataProvider.getManyKeyValue(resource, params);
    }
    if (resource == "key-value-by-coding") {
      return dataProvider.getManyKeyValue(resource, params);
    }
    if (resource == "tags-for-show") {
      return dataProvider.getManyTagsForShow(resource, params);
    }

    if (resource == "editorials-autocomplete") {
      resource = "mpm-row-schedule-types";
      query = JSON.stringify({ where: { type: { $in: params.ids } } });
    } else if (resource == "progammes-by-prorgamme_id") {
      resource = "programmes";
      query = JSON.stringify({ where: { programme_id: { $in: params.ids } } });
      // } else if (resource == "categories") {
      //   resource = "categories/";
      //   query = JSON.stringify({ where: { category_id: { $in: params.ids } } });
      //
    } else if (resource == "channels-by-network_code") {
      resource = "channels";
      query = JSON.stringify({ where: { network_code: { $in: params.ids } } });
    } else if (resource == "clip-by-clip_id") {
      resource = "clips";
      query = JSON.stringify({ where: { clip_id: { $in: params.ids } } });
    } else if (resource == "programmes-autocomplete") {
      resource = "programmes";
      query = JSON.stringify({ where: { id: { $in: params.ids } } });
    } else if (resource == "episodes-autocomplete") {
      resource = "episodes";
      query = JSON.stringify({ where: { episode_id: { $in: params.ids } } });
    } else if (resource == "mpm-page-autocomplete") {
      resource = "mpm-pages";
      query = JSON.stringify({ where: { id: { $in: params.ids } } });
    } else if (resource == "clips-autocomplete") {
      resource = "clips";
      query = JSON.stringify({ where: { id: { $in: params.ids } } });
    } else if (resource == "tags-autocomplete") {
      resource = "tags";
      query = JSON.stringify({ where: { tag_id: { $in: params.ids } } });
    } else if (resource == "im-canned-messages-autocomplete") {
      resource = "im-canned-messages";
      query = JSON.stringify({ where: { id: { $in: params.ids } } });
    } else if (resource == "tags-checkbox") {
      resource = "tags";
      query = JSON.stringify({
        where: {
          type: "subscription_tag",
          name_en: {
            $not: {
              $regex: "TVOD_",
              $options: "i",
            },
          },
        },
        limit: 99999,
      });
    } else if (resource == "channels-autocomplete-2") {
      return await dataProvider.getManyChannelsAutocomplete2(params);
    } else if (resource == "channels-autocomplete") {
      resource = "channels";
      query = JSON.stringify({ where: { id: { $in: params.ids } } });
    } else if (resource == "vodlives-autocomplete") {
      resource = "vod-lives";
      query = JSON.stringify({ where: { id: { $in: params.ids } } });
    } else if (resource == "tags") {
      if (!params.ids) {
        return {
          data: [],
          total: 0,
        };
      }
      if (params.ids.length > 10) {
        query = JSON.stringify({
          limit: params.ids.length,
          where: { tag_id: { $in: params.ids } },
        });
      } else {
        query = JSON.stringify({ where: { tag_id: { $in: params.ids } } });
      }
    } else if (resource == "tags-programme-show") {
      resource = "tags";
      query = JSON.stringify({
        limit: 100,
        where: { tag_id: { $in: params.ids } },
      });
    } else if (resource == "artists") {
      var arr = [];
      for (var id of params.ids) {
        arr.push({ ref_type: id.ref_type, ref_id: id.ref_id });
      }

      query = JSON.stringify({ where: { $or: arr } });
    } else if (resource == "mpm-rows") {
      query = JSON.stringify({ limit: 99999, where: { _id: { $in: params.ids } } });
    } else {
      query = JSON.stringify({ where: { _id: { $in: params.ids } } });
    }

    const url = `${apiUrl}/${resource}?query=${query}`;
    console.log("getMany : url = " + url);

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      if (originalResource == "tags") {
        item.id = item.tag_id;
      } else if (originalResource == "tags-checkbox") {
        item.id = item.tag_id;
      } else if (originalResource == "progammes-by-prorgamme_id") {
        item.id = item.programme_id;
      } else if (originalResource == "clip-by-clip_id") {
        item.id = item.clip_id;
      } else if (originalResource == "channels-by-network_code") {
        item.id = item.network_code;
        // }
        // else if (originalResource == "categories") {
        //   item.id = item.category_id;
      } else if (originalResource == "tags-programme-show") {
        item.id = item.tag_id;
      }
      // else if (resource == "mpm-rows") {
      //   //Because ref is keyword, we need to rename it
      //   delete Object.assign(item, { ["ref_x"]: item["ref"] })["ref"];
      //   delete Object.assign(item, { ["id"]: item["_id"] })["_id"];

      //   if (item["type"] == "editorial_pick") {
      //     if (item["ref_x"].startsWith("events")) {
      //       item["type"] = "event";
      //     }
      //   }
      // }
      else if (resource == "editorials-autocomplete") {
        item.id = item.type;
      } else if (originalResource == "mpm-row-tag-autocomplete") {
        item.id = item.tag_id;
      } else {
        delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
      }
    }

    var items = json.data;

    //Follow the original orders because using ArrayInput
    if (originalResource == "tags") {
      var result = [];
      for (const id of params.ids) {
        result.push(items.find((item) => item.id == id));
      }
      items = result;
    }

    console.log("getMany : resource = " + resource + " , result = ");
    console.log(items);

    return {
      data: items,
      total: json.total,
    };
  },

  getManyChannelsAutocomplete2: async (params) => {
    console.log("dataProvider . getManyChannelsAutocomplete2 : params = ");
    console.log(params);

    const resource = "channels";
    const query = JSON.stringify({ where: { channel_no: { $in: params.ids } } });

    const url = `${apiUrl}/${resource}?query=${query}`;
    console.log("dataProvider . getManyChannelsAutocomplete2 : url = " + url);

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      item._id = item.channel_no;
      item.channel_text = item.name_tc + " - " + item.network_code;
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . getManyChannelsAutocomplete2 : json = ");
    console.log(json);

    return {
      data: json.data,
      total: json.data.length,
    };
  },

  getManySubcatFilters: async (resource, params) => {
    console.log("getManySubcatFilters : params = ");
    console.log(params);

    const query = { where: { category_tag_id: parseInt(params) } };

    const url = `${apiUrl}/subcat-filters?query=${JSON.stringify(query)}`;
    console.log("getManySubcatFilters : url = ");
    console.log(url);

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    console.log("getManySubcatFilters : json = ");
    console.log(json);

    for (const j of json.data) {
      j.id = j.sub_category_tag_id;
    }

    return {
      data: json.data,
      total: json.total,
    };
  },

  getManyTagsForShow: async (resource, params) => {
    console.log("getManyTagsForShow : params = ");
    console.log(params);
    var query = "";
    var originalResource = resource;

    if (!params.ids) {
      return {
        data: [],
        total: 0,
      };
    }
    if (params.ids.length > 10) {
      query = JSON.stringify({
        limit: params.ids.length,
        where: { tag_id: { $in: params.ids } },
      });
    } else {
      query = JSON.stringify({ where: { tag_id: { $in: params.ids } } });
    }

    const url = `${apiUrl}/tags?query=${query}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    console.log("getManyTagsForShow : json = ");
    console.log(json);

    for (const item of json.data) {
      // eslint-disable-next-line
      item.id = item.tag_id;
    }

    var items = json.data;

    //Follow the original orders because using ArrayInput
    var result = [];
    for (const id of params.ids) {
      const fetchTag = items.find((item) => item.id == id);
      if (fetchTag) {
        result.push(fetchTag);
      } else {
        result.push({
          id: id,
          // tag_id: null,
          name_en: "##DELETED##",
          name_tc: "##DELETED##",
        });
      }
    }

    console.log("getManyTagsForShow : result = ");
    console.log(result);

    items = result;

    return {
      data: items,
      total: json.total,
    };
  },

  getManyProfilesMapping: async (resource, params) => {
    console.log("getManyProfilesMapping : resource = " + resource + " , params = ");
    console.log(params);

    var query = JSON.stringify({
      where: {
        $and: [{ bitrate_profile_id: params.bitrate_profile_id }, { coding_profile_id: params.coding_profile_id }, { drm_profile_id: params.drm_profile_id }],
      },
    });

    const url = `${apiUrl}/${resource}?query=${query}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    console.log("getManyProfilesMapping : json = ");
    console.log(json);

    return {
      data: json,
      total: json.total,
    };
  },

  getManyKeyValue: async (resource, params) => {
    console.log("getManyKeyValue");
    console.log(resource);
    var query = "";
    var originalResource = resource;

    if (resource == "key-value-by-quality") {
      resource = "key-values";
      query = JSON.stringify({ where: { key: { $in: "quality" } } });
    }
    if (resource == "key-value-by-event") {
      resource = "key-values";
      query = JSON.stringify({ where: { key: { $in: "event_type" } } });
    }
    if (resource == "key-value-by-platform") {
      resource = "key-values";
      query = JSON.stringify({ where: { key: { $in: "platform" } } });
    }
    if (resource == "key-value-by-drm") {
      resource = "key-values";
      query = JSON.stringify({ where: { key: { $in: "drm" } } });
    }
    if (resource == "key-value-by-coding") {
      resource = "key-values";
      query = JSON.stringify({ where: { key: { $in: "coding" } } });
    }

    const url = `${apiUrl}/${resource}?query=${query}`;

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    let res = {};
    let data = [];
    if (originalResource == "key-value-by-event") {
      for (const value of json.data[0].values) {
        data.push({ name: value });
      }
    } else {
      for (const value of json.data[0].values) {
        data.push({ id: value, name: value });
      }
    }

    return {
      data: data,
      total: data.length,
    };
  },

  getManyReference: async (resource, params) => {
    console.log("getManyReference");

    console.log(resource);
    if (resource == "programme-ref") {
      return dataProvider.getManyReferenceProgramme(resource, params);
    }

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field, //order by _id, modify field name
      page: page,
      limit: perPage,
      where: { [params.target]: params.id },
    };
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/${resource}?query=${query}`;
    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }
    return {
      data: json.data,
      total: json.total,
    };
    /* 
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
    */
    //throw new Error('Not Implemented');
  },

  updateSlotOrder: async (params) => {
    console.log("dataProvider . updateSlotOrder : params = ");
    console.log(params);

    const url = `${apiUrl}/mpm-row-schedules/slot?type=${params.type}&slot_id=${params.slot_id}&new_slot_id=${params.new_slot_id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      // body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . updateSlotOrder : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  updateSlot: async (resource, params) => {
    console.log("dataProvider . updateSlot : params = ");
    console.log(params);

    var data = {};
    data["slot_id"] = parseInt(params.data.slot_id);
    data["type"] = params.data.type;
    data["content_id"] = params.data.content_id ? params.data.content_id.toString() : "";
    data["content_type"] = params.data.content_type;
    data["caption_en"] = null;
    data["caption_tc"] = null;
    data["enabled"] = params.data.enabled;
    data["start_time"] = moment(params.data.start_time);
    Object.assign(data, convertRegionToJson(params.data.region));

    if (params.data.hasOwnProperty("portrait_poster_base64")) {
      if (params.data.portrait_poster_base64) {
        data["portrait_poster_base64"] = await blobToData(params.data.portrait_poster_base64.rawFile);
      } else {
        // set to null -> submit a null to delete the poster
        data["portrait_poster_base64"] = null;
      }
    }
    if (params.data.hasOwnProperty("landscape_poster_base64")) {
      if (params.data.landscape_poster_base64) {
        data["landscape_poster_base64"] = await blobToData(params.data.landscape_poster_base64.rawFile);
      } else {
        // set to null -> submit a null to delete the poster
        data["landscape_poster_base64"] = null;
      }
    }

    if ("programme" == params.data.content_type) {
      if (params.data.caption_en != params.data.programme_name_en) {
        data["caption_en"] = params.data.caption_en;
      }

      if (params.data.caption_tc != params.data.programme_name_tc) {
        data["caption_tc"] = params.data.caption_tc;
      }
    } else if ("episode" == params.data.content_type) {
      data["caption_en"] = params.data.caption_en;
      data["caption_tc"] = params.data.caption_tc;
    } else if ("live" == params.data.content_type) {
      if (params.data.caption_en != params.data.channel_name_en) {
        data["caption_en"] = params.data.caption_en;
      }

      if (params.data.caption_tc != params.data.channel_name_tc) {
        data["caption_tc"] = params.data.caption_tc;
      }
    } else if ("vodlive" == params.data.content_type) {
      if (params.data.caption_en != params.data.vodlive_name_en) {
        data["caption_en"] = params.data.caption_en;
      }

      if (params.data.caption_tc != params.data.vodlive_name_tc) {
        data["caption_tc"] = params.data.caption_tc;
      }
    } else if ("clip" == params.data.content_type) {
      if (params.data.caption_en != params.data.clip_name_en) {
        data["caption_en"] = params.data.caption_en;
      }

      if (params.data.caption_tc != params.data.clip_name_tc) {
        data["caption_tc"] = params.data.caption_tc;
      }
    } else if ("page" == params.data.content_type) {
      data["caption_en"] = params.data.caption_en;
      data["caption_tc"] = params.data.caption_tc;
    } else if (["horse_racecard", "horse_history", "horse_tips"].includes(params.data.content_type)) {
      data["caption_en"] = params.data.caption_en;
      data["caption_tc"] = params.data.caption_tc;
    } else if ("search" == params.data.content_type) {
      data["search_keyword"] = params.data.search_keyword;
      data["caption_en"] = params.data.caption_en;
      data["caption_tc"] = params.data.caption_tc;
    }

    console.log("dataProvider . updateSlot : data = ");
    console.log(data);

    const url = `${apiUrl}/mpm-row-schedules/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . updateSlot : json = ");
    console.log(json);

    return {
      data: json,
    };
  },
  updateSubcatFilterOverwriteOrder: async (params) => {
    console.log("dataProvider . updateSubcatFilterOverwriteOrder : params = ");
    console.log(params);

    var data = {
      order: params.data.order,
    };

    const url = `${apiUrl}/subcat-filter-overwrite/${params.id}/order`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . updateSubcatFilterOverwriteOrder : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  updateSubcatFilterOverwrite: async (params) => {
    console.log("dataProvider . updateSubcatFilterOverwrite : params = ");
    console.log(params);

    var data = params.data;

    const url = `${apiUrl}/subcat-filter-overwrite/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . updateSubcatFilterOverwrite : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  updatePoster: async (resource, params) => {
    console.log("UpdatePoster");
    delete params.data.id;
    delete params.data.created_at;
    delete params.data.modified_at;
    delete params.data.__v;
    let render = {};
    let resDataPortraitSmall = "";
    let resDataPortraitMedium = "";
    let resDataPortraitLarge = "";
    let resDataLandscapeSmall = "";
    let resDataLandscapeMedium = "";
    let resDataLandscapeLarge = "";

    // if (params.data.portrait_small_base64) {
    //   resDataPortraitSmall = await blobToData(params.data.portrait_small_base64.rawFile);
    //   render.portrait_small_base64 = resDataPortraitSmall;
    // }
    // if (params.data.portrait_medium_base64) {
    //   resDataPortraitMedium = await blobToData(params.data.portrait_medium_base64.rawFile);
    //   render.portrait_medium_base64 = resDataPortraitMedium;
    // }
    // if (params.data.portrait_large_base64) {
    //   resDataPortraitLarge = await blobToData(params.data.portrait_large_base64.rawFile);
    //   render.portrait_large_base64 = resDataPortraitLarge;
    // }

    // use resizer
    if (params.data.portrait_large_base64) {
      const pl = params.data.portrait_large_base64.rawFile;
      resDataPortraitSmall = await blobToData(await resizeFile(pl, portraitSmallSize.width, portraitSmallSize.height));
      render.portrait_small_base64 = resDataPortraitSmall;
      resDataPortraitMedium = await blobToData(await resizeFile(pl, portraitMediumSize.width, portraitMediumSize.height));
      render.portrait_medium_base64 = resDataPortraitMedium;
      resDataPortraitLarge = await blobToData(await resizeFile(pl, portraitLargeSize.width, portraitLargeSize.height));
      render.portrait_large_base64 = resDataPortraitLarge;
    }

    // if (params.data.landscape_small_base64) {
    //   resDataLandscapeSmall = await blobToData(params.data.landscape_small_base64.rawFile);
    //   render.landscape_small_base64 = resDataLandscapeSmall;
    // }
    // if (params.data.landscape_medium_base64) {
    //   resDataLandscapeMedium = await blobToData(params.data.landscape_medium_base64.rawFile);
    //   render.landscape_medium_base64 = resDataLandscapeMedium;
    // }
    // if (params.data.landscape_large_base64) {
    //   resDataLandscapeLarge = await blobToData(params.data.landscape_large_base64.rawFile);
    //   render.landscape_large_base64 = resDataLandscapeLarge;
    // }

    // use resizer
    if (params.data.landscape_large_base64) {
      const ll = params.data.landscape_large_base64.rawFile;
      resDataLandscapeSmall = await blobToData(await resizeFile(ll, landscapeSmallSize.width, landscapeSmallSize.height));
      render.landscape_small_base64 = resDataLandscapeSmall;
      resDataLandscapeMedium = await blobToData(await resizeFile(ll, landscapeMediumSize.width, landscapeMediumSize.height));
      render.landscape_medium_base64 = resDataLandscapeMedium;
      resDataLandscapeLarge = await blobToData(await resizeFile(ll, landscapeLargeSize.width, landscapeLargeSize.height));
      render.landscape_large_base64 = resDataLandscapeLarge;
    }

    render.programme_id = parseFloat(params.data.programme_id);
    render.start_time = params.data.start_time;
    render.end_time = params.data.end_time;

    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  updateEvent: async (resource, params) => {
    console.log("UpdateEvent");
    delete params.data.id;
    delete params.data.created_at;
    delete params.data.modified_at;
    delete params.data.__v;
    let render = {};

    if (params.data.portrait_poster_base64) {
      const resDataPortrait = await blobToData(params.data.portrait_poster_base64.rawFile);
      render.portrait_poster_base64 = resDataPortrait;
    }
    if (params.data.landscape_poster_base64) {
      const resDataLandscape = await blobToData(params.data.landscape_poster_base64.rawFile);
      render.landscape_poster_base64 = resDataLandscape;
    }

    render.title = params.data.title;
    render.type = params.data.key;

    let codes = [];
    let topic = [];
    for (const code of params.data.codes) {
      if (code.sports_event_code && typeof code.sports_event_code == "string") {
        topic = code.sports_event_code.split(",");
      } else {
        topic = code.sports_event_code;
      }

      codes.push({
        sports_topic_code: code.sports_topic_code,
        sports_event_code: topic,
      });
    }

    render.codes = codes;
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  updateKeyValue: async (resource, params) => {
    console.log("UpdateKeyValue");
    delete params.data.id;
    delete params.data.created_at;
    delete params.data.modified_at;
    delete params.data.__v;
    delete params.data.key;

    const url = `${apiUrl}/${resource}/${params.id}`;
    let values = [];
    if (params.data.values && typeof params.data.values == "string") {
      values = params.data.values.split(",");
    } else {
      values = params.data.values;
    }
    delete params.data.values;
    const render = params.data;
    render.values = values;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },
  updateEventType: async (resource, params) => {
    console.log("UpdateKeyValue");
    delete params.data.id;
    delete params.data.created_at;
    delete params.data.modified_at;
    delete params.data.__v;
    delete params.data.key;

    const url = `${apiUrl}/key-values/${params.id}`;
    let values = [];
    for (const val of params.data.values) {
      values.push(val.name);
    }
    console.log(values);
    let render = {};
    render.values = values;
    console.log(render);
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },
  updateMcdnAppName: (resource, params) => {
    console.log("updateMcdnAppName");
    return dataProvider.updateEventType(resource, params);
  },
  updateHorseRaceData: async (resource, params) => {
    console.log("updateHorseRaceData");
    delete params.data.id;
    delete params.data.created_at;
    delete params.data.modified_at;
    delete params.data.__v;
    delete params.data.image_url;
    // delete params.data.race_no;
    let render = params.data;
    if (params.data.image_base64) {
      const resDataimage = await blobToData(params.data.image_base64.rawFile);
      delete params.data.image_base64;
      render.image_base64 = resDataimage;
    }

    let race_no = parseFloat(params.data.race_no);

    render.race_no = race_no;
    console.log(render);
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },
  updateEpisode: async (resource, params) => {
    console.log("updateEpisode");

    let render = {};
    render.free_start_time = params.data.free_start_time;
    render.free_end_time = params.data.free_end_time;
    render.pay_start_time = params.data.pay_start_time;
    render.pay_end_time = params.data.pay_end_time;
    render.gold_start_time = params.data.gold_start_time;
    render.gold_end_time = params.data.gold_end_time;
    render.app_web_free_start_time = params.data.app_web_free_start_time;
    render.app_web_free_end_time = params.data.app_web_free_end_time;
    render.is_preview = params.data.is_preview;
    render.enabled = params.data.enabled;

    render.platform_time = params.data.platform_time;
    for (const pt of render.platform_time) {
      if (pt.type == "free") {
        pt.start_time = params.data.app_web_free_start_time;
        pt.end_time = params.data.app_web_free_end_time;
      } else if (pt.type == "early_release") {
        pt.start_time = params.data.app_web_first_start_time;
        pt.end_time = params.data.app_web_first_end_time;
      }
    }

    console.log(render);
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },
  updateAdGroup: async (resource, params) => {
    console.log("updateAdGroup");
    console.log(params);

    const url = `${apiUrl}/${resource}/${params.id}/programme-id`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(params.data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  updateKidsMenuTree: async (resource, params) => {
    console.log("updateKidsMenuTree");

    const data = {
      profile_class: params.data.profileClass == "kids" ? ["kids"] : ["general"],
      description: params.data.description,
      start_time: params.data.startTime,
      menu_tree: params.data.menuTree,
    };

    const url = `${apiUrl}/menu-tree/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  update: async (resource, params) => {
    console.log("update : resource = " + resource + " , params = ");
    console.log(params);

    // validation:
    if (resource == "programmes") {
      // backend validation (this is supposed not to triggered because has front end validation)
      if ((params.data.start_time && !params.data.end_time) || (!params.data.start_time && params.data.end_time)) {
        throw Error("Please input both overwrite start time and end time");
      }
    }

    if (resource == "kids-menu-tree") {
      return dataProvider.updateKidsMenuTree(resource, params);
    }

    if (resource == "im-rooms") {
      return dataProvider.updateIMChatroom(resource, params);
    }

    // proceed:
    if (resource == "mpm-home-page" || resource == "kids-mpm-home-page") {
      resource = "mpm-pages";
    }

    if (resource == "im-canned-messages") {
      return dataProvider.updateIMCannedMessages(resource, params);
    }

    if (resource == "im-icons") {
      return dataProvider.updateIMIcon(resource, params);
    }

    if (resource == "profile-icon-order") {
      return dataProvider.updateProfileIconOrder(resource, params);
    }

    if (resource == "subcat-filters") {
      return dataProvider.updateSubcatFilters(resource, params);
    }
    if (resource == "subcat-filter-overwrite-edit") {
      return dataProvider.updateSubcatFilterOverwrite(params);
    }
    if (resource == "mpm-pages") {
      return dataProvider.updateMpmPages(resource, params);
    }
    if (resource == "ads-adgroups") {
      return dataProvider.updateAdGroup(resource, params);
    }
    if (resource == "mpm-rows") {
      return dataProvider.updateMpmRow(resource, params);
    }
    if (resource == "push-notifications") {
      return dataProvider.updateNotification(resource, params);
    }
    if (resource == "key-values") {
      return dataProvider.updateKeyValue(resource, params);
    }
    if (resource == "event-type") {
      return dataProvider.updateEventType(resource, params);
    }
    if (resource == "mcdn-appname") {
      return dataProvider.updateMcdnAppName(resource, params);
    }
    if (resource == "episodes") {
      return dataProvider.updateEpisode(resource, params);
    }
    if (resource == "channels") {
      return dataProvider.updateChannel(resource, params);
    }
    if (resource == "slots") {
      return dataProvider.updateSlot(resource, params);
    }

    if (resource == "hot-searchs-order") {
      return dataProvider.updateHotSearchOrder(resource, params);
    }

    if (resource == "profile-icons" || resource == "kids-profile-icons") {
      return dataProvider.updateProfileIcon(resource, params);
    }

    if (resource == "events") {
      return dataProvider.updateEvent(resource, params);
    }

    if (resource == "horserace-data") {
      return dataProvider.updateHorseRaceData(resource, params);
    }
    if (resource == "editorial-types") {
      return dataProvider.updateEditorialType(resource, params);
    }
    if (resource == "page-row-paths") {
      return dataProvider.updatePagePath(params);
    }
    if (resource == "mpm-pages") {
      delete params.data.zoneimage_app_en;
      if (params.data.zoneimage_app_en_base64) {
        params.data.zoneimage_app_en_base64 = await blobToData(params.data.zoneimage_app_en_base64.rawFile);
      }
      delete params.data.zoneimage_app_tc;
      if (params.data.zoneimage_app_tc_base64) {
        params.data.zoneimage_app_tc_base64 = await blobToData(params.data.zoneimage_app_tc_base64.rawFile);
      }
      delete params.data.zoneimage_stb_en;
      if (params.data.zoneimage_stb_en_base64) {
        params.data.zoneimage_stb_en_base64 = await blobToData(params.data.zoneimage_stb_en_base64.rawFile);
      }
      delete params.data.zoneimage_stb_tc;
      if (params.data.zoneimage_stb_tc_base64) {
        params.data.zoneimage_stb_tc_base64 = await blobToData(params.data.zoneimage_stb_tc_base64.rawFile);
      }
      delete params.data.zoneimage_web_en;
      if (params.data.zoneimage_web_en_base64) {
        params.data.zoneimage_web_en_base64 = await blobToData(params.data.zoneimage_web_en_base64.rawFile);
      }
      delete params.data.zoneimage_web_tc;
      if (params.data.zoneimage_web_tc_base64) {
        params.data.zoneimage_web_tc_base64 = await blobToData(params.data.zoneimage_web_tc_base64.rawFile);
      }
    }

    if (resource == "hot-searchs" || resource == "kids-hot-searchs") {
      return dataProvider.updateHotSearchs(resource, params);
    }

    //Delete basic useless params
    delete params.data.id;
    delete params.data.created_at;
    delete params.data.modified_at;
    delete params.data.__v;

    if (resource == "vod-live-testing-data") {
      resource = "vod-lives";
    }

    if (resource == "programme-overwrite-posters") {
      return dataProvider.updatePoster(resource, params);
    } else {
      var data = params.data;

      // if (resource == "hot-searchs") {
      //   data = {
      //     search: params.data.search,
      //     enabled: params.data.enabled,
      //   };
      // } else
      if (resource == "programmes") {
        data = {
          enabled: params.data.enabled,
          start_time: HKDate(params.data.start_time),
          end_time: HKDate(params.data.end_time),
          show_offshelf: params.data.show_offshelf,
          seo_desc_en: params.data.seo_desc_en,
          seo_desc_tc: params.data.seo_desc_tc,
          gold_content: params.data.gold_content,
          is_early_release_for_gold_user: params.data.is_early_release_for_gold_user,
          early_release_for_gold_user_end_time: HKDate(params.data.early_release_for_gold_user_end_time),
        };
      }

      const url = `${apiUrl}/${resource}/${params.id}`;
      console.log("Generic Update : url=");
      console.log(url);
      console.log(data);
      const { json } = await httpClient(url, {
        method: "PUT",
        headers: await getAuthHeaders(),
        body: JSON.stringify(data),
      });

      console.log("Generic Update : json=");
      console.log(json);

      // eslint-disable-next-line
      delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
      return {
        data: json,
      };
    }
  },

  updateUserPassword: async (params) => {
    console.log("updateUserPassword : params = ");
    console.log(params);

    const data = {
      password: params.data.password,
    };
    const url = `${apiUrl}/users/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("updateUserPassword : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  updateSubcatFilters: async (resource, params) => {
    console.log("updateSubcatFilters");
    console.log(params);

    const data = { order: params.order };

    const url = `${apiUrl}/subcat-filters/${params.id}/order`;

    console.log("updateSubcatFilters : url = ");
    console.log(url);

    console.log("updateSubcatFilters : data = ");
    console.log(data);

    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  updateSubcatFilterOverwrite: async (params) => {
    console.log("dataProvider . updateSubcatFilterOverwrite : params = ");
    console.log(params);

    const data = {
      // "category_tag_id": parseInt(params.data.id),
      sub_category_tag_id: parseInt(params.data.sub_category_tag_id),
    };

    const url = `${apiUrl}/subcat-filter-overwrite/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . updateSubcatFilterOverwrite : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  updateChannel: async (resource, params) => {
    console.log("dataProvider . updateChannels : params = ");
    console.log(params);

    // //Delete basic useless params
    delete params.data.id;
    delete params.data.created_at;
    delete params.data.modified_at;
    delete params.data.__v;
    delete params.data.landscape_poster;
    delete params.data.portrait_poster;
    delete params.data.disclaimer_image;
    delete params.data.text_tc;
    delete params.data.text_en;
    delete params.data.channel_no;
    delete params.data.hd_sd;
    delete params.data.mcdn_appname_select;

    let resDataPortrait;
    let resDataLandscape;
    let resDisclaimer;
    if (params.data.portrait_poster_base64) {
      resDataPortrait = await blobToData(params.data.portrait_poster_base64.rawFile);
    }
    if (params.data.landscape_poster_base64) {
      resDataLandscape = await blobToData(params.data.landscape_poster_base64.rawFile);
    }
    if (params.data.disclaimer_image_base64) {
      resDisclaimer = await blobToData(params.data.disclaimer_image_base64.rawFile);
    }

    const start_time = HKDate(params.data.start_time);
    const end_time = HKDate(params.data.end_time);
    // const path = params.data.path ?? "";

    const data = params.data;

    // data.path = path;
    data.start_time = start_time;
    data.end_time = end_time;
    data.landscape_poster_base64 = resDataLandscape;
    data.portrait_poster_base64 = resDataPortrait;
    data.disclaimer_image_base64 = resDisclaimer;
    data.profile_class = data.made_for_kid ? ["general", "kids"] : ["general"];
    Object.assign(data, convertRegionToJson(params.data.region));

    delete data.made_for_kid;

    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    console.log("dataProvider . updateChannels : json = ");
    console.log(json);

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  approverApproveNotification: async (id) => {
    console.log("dataProvider . approverApproveNotification : id= ", id);

    const url = `${apiUrl}/push-notifications/approve/${id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      // body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . approverApproveNotification : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  removeVipoData: async (id) => {
    console.log("dataProvider . removeVipoData : id = " + id);

    const url = `${apiUrl}/vipo-blacklist/${id}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      headers: await getAuthHeaders(),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . removeVipoData : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  // mocApproveNotification: async (id) => {
  //   console.log("dataProvider . mocApproveNotification : id= ", id);

  //   const url = `${apiUrl}/push-notifications/verify/${id}`;
  //   const { json } = await httpClient(url, {
  //     method: "PUT",
  //     headers: await getAuthHeaders(),
  //     // body: JSON.stringify(data),
  //   });
  //   // eslint-disable-next-line
  //   delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

  //   console.log("dataProvider . mocApproveNotification : json = ");
  //   console.log(json);

  //   return {
  //     data: json,
  //   };
  // },

  rejectNotification: async (id) => {
    console.log("dataProvider . rejectNotification : id= ", id);

    const url = `${apiUrl}/push-notifications/reject/${id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      // body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . rejectNotification : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  cancelNotification: async (id) => {
    console.log("dataProvider . cancelNotification : id= ", id);

    const url = `${apiUrl}/push-notifications/cancel/${id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      // body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . cancelNotification : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  updateNotification: async (resource, params) => {
    console.log("dataProvider . updateNotification : params = ");
    console.log(params);

    //Delete basic useless params
    delete params.data.id;
    delete params.data.created_at;
    delete params.data.modified_at;
    delete params.data.programme;
    delete params.data.channel_id;
    delete params.data.episode_id;
    delete params.data.__v;
    delete params.data.master_message_id;
    delete params.data.tag;

    // push now
    var scheduled_push_start_date = HKDate(params.data.scheduled_push_start_date);
    if (params.data.push_now_toggle) {
      scheduled_push_start_date = moment().toISOString();
    } else {
      // push schedule date validation
      if (moment(scheduled_push_start_date) < moment()) throw new Error("Please input the correct scheduled push start date/ time");
    }
    delete params.data.push_now_toggle;

    const accessToken = await getAccessToken();
    const decodedToken = decodeJwt(accessToken);
    var data = params.data;
    data.modified_by = decodedToken.sub;

    // Photo
    delete params.data.image_url;
    if (params.data.image_base64) {
      if (params.data.image_base64 == "remove") {
        params.data.image_base64 = null;
      } else {
        params.data.image_base64 = await blobToData(params.data.image_base64.rawFile);
      }
    }

    delete data.scheduled_push_start_date;
    data.scheduled_push_start_date = scheduled_push_start_date;

    console.log("dataProvider . updateNotification : data = ");
    console.log(data);

    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . updateNotification : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  updateHotSearchOrder: async (resource, params) => {
    console.log("updateHotsearchorder");

    const url = `${apiUrl}/hot-searchs/${params.hotSearchId}/order`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify({ order: params.newOrder }),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
    // }
  },

  updateProfileIconOrder: async (resource, params) => {
    console.log("updateProfileIconOrder");

    const url = `${apiUrl}/profile-icons/${params.profileIconId}/order`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify({ order: params.newOrder }),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
    // }
  },

  updateProfileIcon: async (resource, params) => {
    console.log("updateProfileIcon");

    var seriesIcon = params.data.series_icon;
    if (params.data.series_icon_base64) {
      seriesIcon = await blobToData(params.data.series_icon_base64.rawFile);
    }

    const profileIcons = [];
    if (params.data.profile_icons) {
      for (const item of params.data.profile_icons) {
        if (item.icon_base64) {
          profileIcons.push({
            icon_base64: await blobToData(item.icon_base64.rawFile),
            enabled: item.enabled,
          });
        } else {
          profileIcons.push(convertIdTo_Id(item));
        }
      }
    }

    const data = {
      _id: params.id,
      series_tc: params.data.series_tc,
      series_en: params.data.series_en,
      series_icon: seriesIcon,
      enabled: params.data.enabled,
      show_in_frontend: params.data.show_in_frontend,
      // profile_class: params.data.pc == "kids" ? ["general", "kids"] : ["general"],
      profile_class: params.data.profile_class,
      profile_icons: profileIcons,
    };

    // //Delete basic useless params
    // delete params.data.id;
    // delete params.data.created_at;
    // delete params.data.modified_at;
    // delete params.data.__v;
    // delete params.data.order;
    // delete params.data.icon_url;
    // let icon_base64_main = "";
    // if (params.data.icon_base64) {
    //   icon_base64_main = await blobToData(params.data.icon_base64.rawFile);
    // }
    // delete params.data.icon_base64;
    // for (const item of params.data.profile_icons) {
    //   let icon_base64 = "";
    //   let icon_base64_child = {};
    //   if (item.icon_base64) {
    //     item.icon_base64 = await blobToData(item.icon_base64.rawFile);
    //   }
    //   delete item.name_tc;
    //   delete item.name_en;
    // }
    // // delete params.data.profile_icons;
    // const render = params.data;
    // console.log(render);
    // render.icon_base64 = icon_base64_main;
    const url = `${apiUrl}/profile-icons/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  updateEditorialType: async (resource, params) => {
    console.log("data. updateEditorialType : data = ");

    //Delete basic useless params
    delete params.data.id;
    delete params.data.created_at;
    delete params.data.modified_at;
    delete params.data.__v;
    delete params.data.order;
    delete params.data.type;

    // delete params.data.profile_icons;
    const render = params.data;
    console.log(render);
    const url = `${apiUrl}/mpm-row-schedule-types/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });

    console.log("data. updateEditorialType : json = ");
    console.log(json);

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createPoster: async (resource, params) => {
    console.log("createPOoster");
    let render = {};
    // const resDataPortraitSmall = await blobToData(params.data.portrait_small_base64.rawFile);
    // const resDataPortraitMedium = await blobToData(params.data.portrait_medium_base64.rawFile);
    // const resDataPortraitLarge = await blobToData(params.data.portrait_large_base64.rawFile);
    const pl = params.data.portrait_large_base64.rawFile;
    const resDataPortraitSmall = await blobToData(await resizeFile(pl, portraitSmallSize.width, portraitSmallSize.height));
    const resDataPortraitMedium = await blobToData(await resizeFile(pl, portraitMediumSize.width, portraitMediumSize.height));
    const resDataPortraitLarge = await blobToData(await resizeFile(pl, portraitLargeSize.width, portraitLargeSize.height));
    // const resDataLandscapeSmall = await blobToData(params.data.landscape_small_base64.rawFile);
    // const resDataLandscapeMedium = await blobToData(params.data.landscape_medium_base64.rawFile);
    // const resDataLandscapeLarge = await blobToData(params.data.landscape_large_base64.rawFile);
    const ll = params.data.landscape_large_base64.rawFile;
    const resDataLandscapeSmall = await blobToData(await resizeFile(ll, landscapeSmallSize.width, landscapeSmallSize.height));
    const resDataLandscapeMedium = await blobToData(await resizeFile(ll, landscapeMediumSize.width, landscapeMediumSize.height));
    const resDataLandscapeLarge = await blobToData(await resizeFile(ll, landscapeLargeSize.width, landscapeLargeSize.height));

    render.programme_id = parseFloat(params.data.programme_id);
    render.start_time = params.data.start_time;
    render.end_time = params.data.end_time;
    render.portrait_small_base64 = resDataPortraitSmall;
    render.portrait_medium_base64 = resDataPortraitMedium;
    render.portrait_large_base64 = resDataPortraitLarge;
    render.landscape_small_base64 = resDataLandscapeSmall;
    render.landscape_medium_base64 = resDataLandscapeMedium;
    render.landscape_large_base64 = resDataLandscapeLarge;
    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createSlot: async (resource, params) => {
    console.log("createSlot");

    console.log(params.data);

    var data = {};
    data["slot_id"] = parseInt(params.data.slot_id);
    data["type"] = params.data.type;
    data["content_id"] = params.data.content_id ? params.data.content_id.toString() : "";
    data["content_type"] = params.data.content_type;
    data["caption_en"] = null;
    data["caption_tc"] = null;
    data["enabled"] = params.data.enabled;
    data["start_time"] = moment(params.data.start_time);
    data["portrait_poster_base64"] = null;
    data["landscape_poster_base64"] = null;
    Object.assign(data, convertRegionToJson(params.data.region));

    if (params.data.portrait_poster_base64) {
      data["portrait_poster_base64"] = await blobToData(params.data.portrait_poster_base64.rawFile);
    }
    if (params.data.landscape_poster_base64) {
      data["landscape_poster_base64"] = await blobToData(params.data.landscape_poster_base64.rawFile);
    }

    if ("programme" == params.data.content_type) {
      if (params.data.caption_en != params.data.programme_name_en) {
        data["caption_en"] = params.data.caption_en;
      }

      if (params.data.caption_tc != params.data.programme_name_tc) {
        data["caption_tc"] = params.data.caption_tc;
      }
    } else if ("episode" == params.data.content_type) {
      data["caption_en"] = params.data.caption_en;
      data["caption_tc"] = params.data.caption_tc;
    } else if ("live" == params.data.content_type) {
      if (params.data.caption_en != params.data.channel_name_en) {
        data["caption_en"] = params.data.caption_en;
      }

      if (params.data.caption_tc != params.data.channel_name_tc) {
        data["caption_tc"] = params.data.caption_tc;
      }
    } else if ("vodlive" == params.data.content_type) {
      if (params.data.caption_en != params.data.vodlive_name_en) {
        data["caption_en"] = params.data.caption_en;
      }

      if (params.data.caption_tc != params.data.vodlive_name_tc) {
        data["caption_tc"] = params.data.caption_tc;
      }
    } else if ("clip" == params.data.content_type) {
      if (params.data.caption_en != params.data.clip_name_en) {
        data["caption_en"] = params.data.caption_en;
      }

      if (params.data.caption_tc != params.data.clip_name_tc) {
        data["caption_tc"] = params.data.caption_tc;
      }
    } else if ("page" == params.data.content_type) {
      data["caption_en"] = params.data.caption_en;
      data["caption_tc"] = params.data.caption_tc;
    } else if (["horse_racecard", "horse_history", "horse_tips"].includes(params.data.content_type)) {
      data["caption_en"] = params.data.caption_en;
      data["caption_tc"] = params.data.caption_tc;
    } else if ("search" == params.data.content_type) {
      data["search_keyword"] = params.data.search_keyword;
      data["caption_en"] = params.data.caption_en;
      data["caption_tc"] = params.data.caption_tc;
    }

    const url = `${apiUrl}/mpm-row-schedules`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      // body: JSON.stringify(render),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createMpmRow: async (resource, params) => {
    console.log("createMpmRow : params = ");
    console.log(params);

    var data = {};
    // data["filters"] = [];
    data["name"] = params.data.name;

    // template_3 : size in orientation in loopRow
    if (params.data.template_3) {
      data["template"] = params.data.template_3;
    }

    // template_2 : orientation in loopRow
    else if (params.data.template_2) {
      data["template"] = params.data.template_2;

      // template : default template param
    } else {
      data["template"] = params.data.template;
    }

    data["title_en"] = params.data.title_en;
    data["title_tc"] = params.data.title_tc;
    data["type"] = params.data.type;
    data["num_of_rows"] = params.data.num_of_rows;
    data["parent_page_id"] = params.data.page_id;
    data["start_time"] = params.data.start_time;
    data["end_time"] = params.data.end_time;
    data["seo_desc_tc"] = params.data.seo_desc_tc;
    data["seo_desc_en"] = params.data.seo_desc_en;
    data["platform"] = params.data.platform;
    Object.assign(data, convertRegionToJson(params.data.region));

    if (params.data.type == "editorial_pick") {
      data["ref"] = "editorial_pick/" + params.data.editorial_type;
    } else if (params.data.type == "recommendation") {
      if (params.data.recommendation_type == "recom_personalised") {
        data["ref"] = "recom_personalised";
        data["type"] = "recom_personalised";
      } else if (params.data.recommendation_type == "recom_trendy") {
        data["ref"] = "recom_trendy";
        data["type"] = "recom_trendy";
      } else if (params.data.recommendation_type == "recom_universal") {
        data["ref"] = "recom_universal";
        data["type"] = "recom_universal";
      } else if (params.data.recommendation_type == "recom_free_preview") {
        data["ref"] = "recom_free_preview";
        data["type"] = "recom_free_preview";
      }
    } else if (params.data.type == "programme_list") {
      // data["show_in_menu"] = params.data.show_in_menu;
      data["free_episodes_only"] = params.data.free_episodes_only;

      if (params.data.tags) {
        data["ref"] = params.data.tags.map((tag) => tag.tag_value);
      }
      if (params.data.page_path && params.data.path) {
        data["path"] = params.data.page_path + params.data.path;
      } else if (params.data.path) {
        data["path"] = params.data.path;
      }
    } else if (params.data.type == "history") {
      data["ref"] = "history";
    } else if (params.data.type == "latest") {
      data["ref"] = "latest";

      var tags = [];
      if (params.data.tags) {
        tags = params.data.tags.map((tag) => tag.tag_value);
        data["filters"] = [
          {
            tags: tags,
            num_of_items: 20,
          },
        ];
      } else {
        data["filters"] = null;
      }
    } else if (params.data.type == "top_sv") {
      if (params.data.tags) {
        data["ref"] = params.data.tags.map((tag) => tag.tag_value);
      }
    } else if (params.data.type == "clip_list") {
      if (params.data.tags) {
        data["ref"] = params.data.tags.map((tag) => tag.tag_value);
      }
    } else if (params.data.type == "episode_list") {
      if (params.data.programme_id) {
        data["ref"] = params.data.programme_id.toString();
      }
    } else if (params.data.type == "event") {
      data["type"] = "editorial_pick";
      data["ref"] = "events/" + params.data.event_type_value;
    } else if (params.data.type == "category_list") {
      data["ref"] = "category_list";
    }

    const url = `${apiUrl}/mpm-rows`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    console.log("createMpmRow : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  updateMpmRowEmarsysTitle: async (param, mpmRow) => {
    console.log("_updateMpmRowEmarsysTitle : param = ");
    console.log(param);
    console.log("_updateMpmRowEmarsysTitle : mpmRow = ");
    console.log(mpmRow);

    const data = {
      path: param.data.path,
    };
    const url = `${apiUrl}/mpm-rows/${mpmRow.id}/path-homepage`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    console.log("_updateMpmRowEmarsysTitle : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  updateMpmRow: async (resource, params) => {
    console.log("updateMpmRow : params");
    console.log(params);

    var data = {};
    // data["filters"] = [];
    data["name"] = params.data.name;

    // template_3 : size in orientation in loopRow
    if (params.data.template_3) {
      data["template"] = params.data.template_3;
    }

    // template_2 : orientation in loopRow
    else if (params.data.template_2) {
      data["template"] = params.data.template_2;

      // template : default template param
    } else {
      data["template"] = params.data.template;
    }

    data["title_en"] = params.data.title_en;
    data["title_tc"] = params.data.title_tc;
    data["type"] = params.data.type;
    data["num_of_rows"] = params.data.num_of_rows;
    data["parent_page_id"] = params.data.page_id;
    data["start_time"] = params.data.start_time;
    data["end_time"] = params.data.end_time;
    data["seo_desc_tc"] = params.data.seo_desc_tc;
    data["seo_desc_en"] = params.data.seo_desc_en;
    data["platform"] = params.data.platform;
    Object.assign(data, convertRegionToJson(params.data.region));

    data["ref"] = ""; //Reset

    if (params.data.type == "editorial_pick") {
      data["ref"] = "editorial_pick/" + params.data.editorial_type;
    } else if (params.data.type == "recommendation") {
      if (params.data.recommendation_type == "recom_personalised") {
        data["ref"] = "recom_personalised";
        data["type"] = "recom_personalised";
      } else if (params.data.recommendation_type == "recom_trendy") {
        data["ref"] = "recom_trendy";
        data["type"] = "recom_trendy";
      } else if (params.data.recommendation_type == "recom_universal") {
        data["ref"] = "recom_universal";
        data["type"] = "recom_universal";
      } else if (params.data.recommendation_type == "recom_free_preview") {
        data["ref"] = "recom_free_preview";
        data["type"] = "recom_free_preview";
      }
    } else if (params.data.type == "programme_list") {
      // data["show_in_menu"] = params.data.show_in_menu;
      if (params.data.tags) {
        data["ref"] = params.data.tags.map((tag) => tag.tag_value);
      }
      data["free_episodes_only"] = params.data.free_episodes_only;
    } else if (params.data.type == "history") {
      data["ref"] = "history";
    } else if (params.data.type == "latest") {
      data["ref"] = "latest";

      var tags = [];
      if (params.data.tags) {
        tags = params.data.tags.map((tag) => tag.tag_value);
        data["filters"] = [
          {
            tags: tags,
            num_of_items: 20,
          },
        ];
      } else {
        data["filters"] = null;
      }
    } else if (params.data.type == "top_sv") {
      if (params.data.tags) {
        data["ref"] = params.data.tags.map((tag) => tag.tag_value);
      }
    } else if (params.data.type == "clip_list") {
      if (params.data.tags) {
        data["ref"] = params.data.tags.map((tag) => tag.tag_value);
      }
    } else if (params.data.type == "episode_list") {
      if (params.data.programme_id) {
        data["ref"] = params.data.programme_id.toString();
      }
    } else if (params.data.type == "event") {
      data["type"] = "editorial_pick";
      data["ref"] = "events/" + params.data.event_type_value;
    } else if (params.data.type == "category_list") {
      data["ref"] = "category_list";
    }

    console.log("updateMpmRow : data");
    console.log(data);

    const url = `${apiUrl}/mpm-rows/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      // body: JSON.stringify(render),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    // rename 'ref' to 'ref_x'
    if (json.hasOwnProperty("ref")) {
      json.ref_x = json.ref;
      delete json.ref;
    }
    console.log("updateMpmRow : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  create: async (resource, params) => {
    console.log("create");

    if (resource == "hot-searchs" || resource == "kids-hot-searchs") {
      return dataProvider.createHotSearch(resource, params);
    }

    if (resource == "profiles-preferences" || resource == "kids-profiles-preferences") {
      return dataProvider.createProfilesPreference(resource, params);
    }
    if (resource == "im-canned-messages") {
      return dataProvider.createIMCannedMessage(resource, params);
    }

    if (resource == "im-icons") {
      return dataProvider.createIMIcon(resource, params);
    }

    if (resource == "im-rooms") {
      return dataProvider.createIMChatroom(resource, params);
    }

    if (resource == "episode-preview-schedules") {
      return dataProvider.createEpisodePreviewSchedules(params);
    }

    if (resource == "special-program-programs") {
      resource = "programmes/is-sprog";
    }

    if (resource == "import-requests") {
      return dataProvider.createImportRequests(resource, params);
    }

    if (resource == "mpm-pages") {
      return dataProvider.createMpmPages(resource, params);
    }

    if (resource == "mpm-rows") {
      return dataProvider.createMpmRow(resource, params);
    }

    if (resource == "channels") {
      return dataProvider.createChannel(resource, params);
    }
    if (resource == "push-notifications") {
      return dataProvider.createNotification(resource, params);
    }
    if (resource == "key-values") {
      return dataProvider.createKeyValue(resource, params);
    }
    if (resource == "programme-overwrite-posters") {
      return dataProvider.createPoster(resource, params);
    }
    if (resource == "programme-overwrite-poster-module") {
      return dataProvider.createPoster("programme-overwrite-posters", params);
    }
    if (resource == "profile-icons" || resource == "kids-profile-icons") {
      return dataProvider.createProfileIcon(resource, params);
    }
    if (resource == "editorial-types") {
      return dataProvider.CreateEdotorial(resource, params);
    }
    if (resource == "slots") {
      return dataProvider.createSlot(resource, params);
    }
    if (resource == "events") {
      return dataProvider.createEvent(resource, params);
    }
    if (resource == "horserace-data") {
      return dataProvider.createHorseRaceData(resource, params);
    }
    if (resource == "password") {
      delete params.data.new_repeat_password;
      const url = `${apiUrl}/users/change-password`;

      const { json } = await httpClient(url, {
        method: "PUT",
        headers: await getAuthHeaders(),
        body: JSON.stringify(params.data),
      });
      // eslint-disable-next-line
      delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
      return {
        data: json,
      };
    }
    if (resource == "mpm-pages") {
      console.log("Mpm Pages . Create : params = ");
      console.log(params);

      params.data["rows"] = [];
      if (params.data.zoneimage_app_en_base64) {
        params.data.zoneimage_app_en_base64 = await blobToData(params.data.zoneimage_app_en_base64.rawFile);
      }
      if (params.data.zoneimage_app_tc_base64) {
        params.data.zoneimage_app_tc_base64 = await blobToData(params.data.zoneimage_app_tc_base64.rawFile);
      }
      if (params.data.zoneimage_stb_en_base64) {
        params.data.zoneimage_stb_en_base64 = await blobToData(params.data.zoneimage_stb_en_base64.rawFile);
      }
      if (params.data.zoneimage_stb_tc_base64) {
        params.data.zoneimage_stb_tc_base64 = await blobToData(params.data.zoneimage_stb_tc_base64.rawFile);
      }
      if (params.data.zoneimage_web_en_base64) {
        params.data.zoneimage_web_en_base64 = await blobToData(params.data.zoneimage_web_en_base64.rawFile);
      }
      if (params.data.zoneimage_web_tc_base64) {
        params.data.zoneimage_web_tc_base64 = await blobToData(params.data.zoneimage_web_tc_base64.rawFile);
      }

      console.log("Mpm Pages . Create : params2 = ");
      console.log(params);
    }

    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(params.data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createSubcatFilterOverwrite: async (params) => {
    console.log("dataProvider . createSubcatFilterOverwrite : params = ");
    console.log(params);

    const data = {
      category_tag_id: parseInt(params.data.id),
      sub_category_tag_id: parseInt(params.data.sub_category_tag_id),
    };

    const url = `${apiUrl}/subcat-filter-overwrite`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . createSubcatFilterOverwrite : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  CreateEdotorial: async (resource, params) => {
    console.log("Create Edirotial");

    const url = `${apiUrl}/mpm-row-schedule-types`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(params.data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log(json);
    return {
      data: json,
    };
  },

  createHotSearch: async (resource, params) => {
    console.log("createHotSearch");
    const data = {
      search: params.data.search,
      enabled: params.data.enabled,
      profile_class: params.data.pc == "kids" ? ["kids"] : ["general"],
    };

    const url = `${apiUrl}/hot-searchs`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log(json);
    return {
      data: json,
    };
  },

  updateHotSearchs: async (resource, params) => {
    console.log("updateHotSearchs");
    const data = {
      search: params.data.search,
      enabled: params.data.enabled,
      profile_class: params.data.pc == "kids" ? ["kids"] : ["general"],
    };

    const url = `${apiUrl}/hot-searchs/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log(json);
    return {
      data: json,
    };
  },

  createEvent: async (resource, params) => {
    console.log("createEvent");

    let render = {};
    const resDataPortrait = await blobToData(params.data.portrait_poster_base64.rawFile);
    const resDataLandscape = await blobToData(params.data.landscape_poster_base64.rawFile);

    render.portrait_poster_base64 = resDataPortrait;
    render.landscape_poster_base64 = resDataLandscape;
    render.title = params.data.title;
    render.type = params.data.key;

    let codes = [];
    let topic = [];
    for (const code of params.data.codes) {
      if (code.sports_event_code && typeof code.sports_event_code == "string") {
        topic = code.sports_event_code.split(",");
      } else {
        topic = code.sports_event_code;
      }

      codes.push({
        sports_topic_code: code.sports_topic_code,
        sports_event_code: topic,
      });
    }

    render.codes = codes;

    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createKeyValue: async (resource, params) => {
    console.log("createKeyValue");

    let values = [];
    if (params.data.values && typeof params.data.values == "string") {
      values = params.data.values.split(",");
    } else {
      values = params.data.values;
    }
    delete params.data.values;
    const render = params.data;
    render.values = values;
    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createHorseRaceData: async (resource, params) => {
    console.log("createHorseraceData");
    console.log(params);
    let render = params.data;
    if (params.data.image_base64) {
      const resDataimage = await blobToData(params.data.image_base64.rawFile);
      render.image_base64 = resDataimage;
    }
    let race_no = parseFloat(params.data.race_no);
    render.race_no = race_no;
    render.image_type = "Background";

    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(render),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createChannel: async (resource, params) => {
    console.log("createChannel");
    let resDataPortrait;
    let resDataLandscape;
    let resDisclaimer;
    if (params.data.portrait_poster_base64) {
      resDataPortrait = await blobToData(params.data.portrait_poster_base64.rawFile);
    }
    if (params.data.landscape_poster_base64) {
      resDataLandscape = await blobToData(params.data.landscape_poster_base64.rawFile);
    }
    if (params.data.disclaimer_image_base64) {
      resDisclaimer = await blobToData(params.data.disclaimer_image_base64.rawFile);
    }
    delete params.data.libs;
    delete params.data.interactive_channels;
    const data = params.data;
    const start_time = HKDate(params.data.start_time);
    const end_time = HKDate(params.data.end_time);
    delete params.data.end_time;
    delete params.data.hd_sd;
    // delete params.data.path;
    delete params.data.start_time;
    delete params.data.portrait_poster_base64;
    delete params.data.landscape_poster_base64;
    delete params.data.disclaimer_image_base64;
    data.start_time = start_time;
    data.end_time = end_time;
    data.landscape_poster_base64 = resDataLandscape;
    data.portrait_poster_base64 = resDataPortrait;
    data.disclaimer_image_base64 = resDisclaimer;
    Object.assign(data, convertRegionToJson(params.data.region));

    // rename landscape_poster & portrait_poster & disclaimer_image
    data.landscape_poster_url = data.landscape_poster;
    data.portrait_poster_url = data.portrait_poster;
    data.disclaimer_image_url = data.disclaimer_image;
    delete data.landscape_poster;
    delete data.portrait_poster;
    delete data.disclaimer_image;

    // delete path which is brought from clone button
    delete data.path;

    // delete mcdn_appname_select
    delete params.data.mcdn_appname_select;

    data.profile_class = data.made_for_kid ? ["general", "kids"] : ["general"];

    delete data.made_for_kid;

    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createNotification: async (resource, params) => {
    console.log("dataProvider . createNotification : params = ");
    console.log(params);

    const accessToken = await getAccessToken();
    const decodedToken = decodeJwt(accessToken);
    delete params.data.programme;
    delete params.data.channel_id;
    delete params.data.episode_id;

    // push now
    var scheduled_push_start_date = HKDate(params.data.scheduled_push_start_date);
    if (params.data.push_now_toggle) {
      scheduled_push_start_date = moment().toISOString();
    } else {
      // push schedule date validation
      if (moment(scheduled_push_start_date) < moment()) throw new Error("Please input the correct scheduled push start date/ time");
    }
    delete params.data.push_now_toggle;

    var data = params.data;
    data.modified_by = decodedToken.sub;

    // photo
    if (params.data.image_base64) {
      if (params.data.image_base64 == "remove") {
        delete params.data.image_base64;
      } else {
        params.data.image_base64 = await blobToData(params.data.image_base64.rawFile);
      }
    }

    delete data.scheduled_push_start_date;
    data.scheduled_push_start_date = scheduled_push_start_date;

    const url = `${apiUrl}/${resource}`;
    let res = {};
    const pl = data.pla;
    delete data.pla;
    for (const platform of pl) {
      data.platform = platform;

      console.log("dataProvider . createNotification : data = ");
      console.log(data);

      const { json } = await httpClient(url, {
        method: "POST",
        headers: await getAuthHeaders(),
        body: JSON.stringify(data),
      });
      res = json;
      console.log("dataProvider . createNotification : json = ");
      console.log(json);

      // eslint-disable-next-line
      delete Object.assign(res, { ["id"]: res["_id"] })["_id"];
    }

    return {
      data: res,
    };
  },

  createProfileIcon: async (resource, params) => {
    console.log("createProfileIcon");

    const profileIcons = [];
    if (params.data.profile_icons) {
      for (const item of params.data.profile_icons) {
        profileIcons.push({
          icon_base64: await blobToData(item.icon_base64.rawFile),
          enabled: item.enabled,
        });
      }
    }

    const data = {
      series_tc: params.data.series_tc,
      series_en: params.data.series_en,
      series_icon: await blobToData(params.data.series_icon_base64.rawFile),
      enabled: params.data.enabled,
      show_in_frontend: params.data.show_in_frontend,
      profile_class: params.data.pc == "kids" ? ["general", "kids"] : ["general"],
      profile_icons: profileIcons,
    };

    const url = `${apiUrl}/profile-icons`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  delete: async (resource, params) => {
    console.log("delete");

    if (resource == "kids-profile-icons") {
      resource = "profile-icons";
    }

    if (resource == "push-notifications") {
      return dataProvider.deletePushNotification(resource, params);
    }

    if (resource == "slots") {
      return dataProvider.deleteSlot(resource, params);
    }

    if (resource == "editorial-types") {
      resource = "mpm-row-schedule-types";
    }

    if (resource == "menu-tree") {
      return dataProvider.deleteMenuTree(resource, params);
    }

    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      headers: await getAuthHeaders(),
    });

    console.log("delete url = " + url);
    console.log("response = ");
    console.log(json);

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  deleteSubcatFilterOverwrite: async (params) => {
    console.log("dataProvider . deleteSubcatFilterOverwrite : id = " + params.id);

    const url = `${apiUrl}/subcat-filter-overwrite/${params.id}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      headers: await getAuthHeaders(),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . deleteSubcatFilterOverwrite : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  deleteAllSubcatFilterOverwrite: async (params) => {
    console.log("dataProvider . deleteAllSubcatFilterOverwrite : category_tag_id = " + params.category_tag_id);

    const url = `${apiUrl}/subcat-filter-overwrite/category?category_tag_id=${params.category_tag_id}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      headers: await getAuthHeaders(),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . deleteAllSubcatFilterOverwrite : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  deleteMenuTree: async (resource, params) => {
    console.log("deleteMenuTree");

    const url = `${apiUrl}/${resource}/${params.id}`;

    const { json } = await httpClient(url, {
      method: "DELETE",
      headers: await getAuthHeaders(),
    }).catch((err, b, c) => {
      console.log("deleteMenuTree : err=");
      console.log(err);

      for (const k in err) {
        console.log("error key = " + k);
        console.log(err[k]);
      }

      // handle error when cannot delete
      if (err.status == 400) throw err.body.message.join(", ");
    });

    console.log("delete url = " + url);
    console.log("response = ");
    console.log(json);

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    return {
      data: json,
    };
  },

  deleteHotSearches: async (resource, params) => {
    console.log("deleteHotSearches");

    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      headers: await getAuthHeaders(),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },
  deletePushNotification: async (resource, params) => {
    console.log("deletePushNotification");
    console.log(params);
    const url = `${apiUrl}/push-notifications/${params.id}`;

    const data = {
      status: "cancelled",
    };
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },
  deleteSlot: async (resource, params) => {
    console.log("deleteSlot");
    console.log(params);
    const url = `${apiUrl}/mpm-row-schedules/slot?type=${params.previousData.type}&slot_id=${params.previousData.id}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      headers: await getAuthHeaders(),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },
  deleteMany: async (resource, params) => {
    console.log("deleteMany");

    if (resource == "vipo-blacklist") {
      return dataProvider.deleteManyVipoBlacklist(params.ids);
    }

    const headers = await getAuthHeaders();
    if (resource == "editorial-types") {
      resource = "mpm-row-schedule-types";
    }
    const items = await Promise.all(
      params.ids.map((id) => {
        const url = `${apiUrl}/${resource}/${id}`;
        return httpClient(url, {
          method: "DELETE",
          headers: headers,
        }).then(({ json }) => {
          // eslint-disable-next-line
          delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
          return json;
        });
      })
    );
    return { data: items };
  },

  deleteManyVipoBlacklist: async (ids) => {
    console.log("dataProvider . deleteManyVipoBlacklist : ids = " + ids);

    const data = {
      id: ids,
    };
    const url = `${apiUrl}/vipo-blacklist`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("dataProvider . deleteManyVipoBlacklist : json = ");
    console.log(json);

    return {
      data: json,
    };
  },
  // Menu Tree
  postMenuTree: async (params) => {
    console.log("dataProvider . postMenuTree : params = ");
    console.log(params);

    // add back description if it is empty string
    if (!params.description) params.description = "";

    const url = `${apiUrl}/menu-tree`;

    console.log("dataProvider . postMenuTree : url = " + url);
    console.log("dataProvider . postMenuTree : body = " + JSON.stringify(params));

    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(params),
    });

    console.log("dataProvider . postMenuTree : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  putMenuTree: async (id, params) => {
    console.log("dataProvider . putMenuTree : params = ");
    console.log(params);

    const url = `${apiUrl}/menu-tree/${id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(params),
    });

    console.log("dataProvider . putMenuTree : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  // Get Tags
  getTags: async (tagIds) => {
    console.log("dataProvider . getTag : tagIds = ");
    console.log(tagIds);

    var query = {
      page: 1,
      limit: 999999,
      where: {
        $or: tagIds.map((tagId) => ({ tag_id: tagId })),
      },
    };

    const url = `${apiUrl}/tags?query=` + JSON.stringify(query);

    console.log("dataProvider . getTag : url = " + url);

    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    return {
      data: json.data,
    };
  },

  // import requests
  getManyProgrammesForImportRequests: async (resource, params) => {
    console.log("dataProvider . getManyProgrammesForImportRequests : params");
    console.log(params);

    const query = { where: { programme_id: { $in: params.ids } } };
    console.log("dataProvider . getManyProgrammesForImportRequests : query");
    console.log(query);

    const url = `${apiUrl}/programmes?query=${JSON.stringify(query)}`;

    console.log("dataProvider . getManyProgrammesForImportRequests : url");
    console.log(url);

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    console.log("dataProvider . getManyProgrammesForImportRequests : json");
    console.log(json);

    const data2 = json.data.map((item) => {
      return {
        id: item.programme_id,
        name_tc: item.name_tc,
        name_en: item.name_en,
      };
    });

    return {
      data: data2,
      total: json.total,
    };
  },

  createImportRequests: async (resource, params) => {
    console.log("createImportRequests : params = ");
    console.log(params);

    // change id from string to number
    params.data.resource_id = parseInt(params.data.resource_id);

    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(params.data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  updateMpmPages: async (resource, params) => {
    console.log("updateMpmPages : params = ");
    console.log(params);

    // select the page out and check the page_row diff
    const oldPage = await dataProvider.getOne("mpm-pages", { id: params.id });
    const oldRows = oldPage.data.rows;
    const newRows = params.data.rows;
    const removeRows = oldRows.filter((oldRow) => !newRows.includes(oldRow));

    // delete the removeRows
    const removeRowsPromise = removeRows.map(async (removeRow) => {
      const url = `${apiUrl}/mpm-rows/${removeRows}`;
      console.log("updateMpmPages . delete row : url = " + url);
      return httpClient(url, {
        method: "DELETE",
        headers: await getAuthHeaders(),
      })
        .then((result) => {
          console.log("updateMpmPages . delete row Success : url = " + url + " , result = ");
          console.log(result);
        })
        .catch((error) => {
          console.log("updateMpmPages . delete row ERROR : url = " + url + " , error = ");
          console.log(error);
        });
    });

    // wait the delete rows complete
    await Promise.all(removeRowsPromise);

    // process on upload image
    for (const name of ["zoneimage_app_tc", "zoneimage_app_en", "zoneimage_stb_tc", "zoneimage_stb_en", "zoneimage_web_tc", "zoneimage_web_en"]) {
      delete params.data[name];

      if (params.data[name + "_base64"]) {
        if (params.data[name + "_base64"] == "remove") {
          params.data[name + "_base64"] = null;
        } else {
          params.data[name + "_base64"] = await blobToData(params.data[name + "_base64"].rawFile);
        }
      }
    }

    if (params.data.pc == "kids") {
      params.data["profile_class"] = ["general", "kids"];
    } else {
      if (params.data.made_for_kid) {
        params.data["profile_class"] = ["general", "kids"];
      } else {
        params.data["profile_class"] = ["general"];
      }
    }

    //If homepage, check pc
    //If not home page, check made_for_kid
    if (params.data.is_homepage) {
      params.data["profile_class"] = params.data.pc == "kids" ? ["general", "kids"] : ["general"];
    } else {
      if (params.data.made_for_kid) {
        params.data["profile_class"] = ["general", "kids"];
      } else {
        params.data["profile_class"] = ["general"];
      }
    }

    //It's only for is_olympic now
    if (params.data.template && params.data.template.length > 0) {
      params.data["is_olympic"] = true;
    } else {
      params.data["is_olympic"] = false;
    }

    //Delete basic useless params
    delete params.data.id;
    delete params.data.created_at;
    delete params.data.modified_at;
    delete params.data.__v;
    delete params.data.pc;
    delete params.data.template;

    //Delete old params
    delete params.data.banner_image;
    delete params.data.name;

    var data = params.data;

    const url = `${apiUrl}/${resource}/${params.id}`;
    // console.log("updateMpmPages : url=");
    // console.log(url);
    // console.log(data);
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createMpmPages: async (resource, params) => {
    console.log("createMpmPages : params");
    console.log(params);

    params.data["profile_class"] = ["general"];

    for (const name of ["zoneimage_app_tc", "zoneimage_app_en", "zoneimage_stb_tc", "zoneimage_stb_en", "zoneimage_web_tc", "zoneimage_web_en"]) {
      delete params.data[name];

      if (params.data[name + "_base64"]) {
        if (params.data[name + "_base64"] == "remove") {
          params.data[name + "_base64"] = null;
        } else {
          params.data[name + "_base64"] = await blobToData(params.data[name + "_base64"].rawFile);
        }
      }
    }

    //It's only for is_olympic now
    if (params.data.template && params.data.template.length > 0) {
      params.data["is_olympic"] = true;
    } else {
      params.data["is_olympic"] = false;
    }

    params.data.rows = [];

    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(params.data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  postImportRequestForSubcatFilters: async () => {
    const data = {
      from: "sfm",
      resource: "subcat_filters",
      resource_id: -1,
    };

    const url = `${apiUrl}/import-requests`;

    console.log("postImportRequestForSubcatFilters : url = " + url);

    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    console.log("postImportRequestForSubcatFilters : json = ");
    console.log(json);

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  getImportRequestForSubcatFilters: async () => {
    console.log("getImportRequestForSubcatFilters");

    const query = {
      where: { resource: "subcat_filters" },
      sort: "-modified_at",
    };

    const url = `${apiUrl}/import-requests?query=${JSON.stringify(query)}`;
    const result = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    console.log("getImportRequestForSubcatFilters : result = ");
    console.log(result);

    return {
      data: result.json.data[0],
    };
  },

  updatePagePath: async (params) => {
    console.log("updatePagePath");

    console.log(params);

    var data = {
      path: params.data.path,
    };

    const url = `${apiUrl}/mpm-pages/${params.id}/path-ads`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("updatePagePath : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  updatePageRowPath: async (params) => {
    console.log("updatePageRowPath");

    console.log(params);

    var data = {
      path: params.path,
    };

    const url = `${apiUrl}/mpm-rows/${params.page_id}/path-ads`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    console.log("updatePageRowPath : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  createMany: async (resource, params) => {
    console.log("createMany");

    if (resource == "episode-preview-schedules") {
      return dataProvider.createManyEpisodePreviewSchedules(params);
    }
  },

  createManyEpisodePreviewSchedules: async (params) => {
    console.log("createManyEpisodePreviewSchedules : params = ");
    console.log(params);

    const rows = [];

    const platformColumn = "0";
    const programmeIdColumn = "1";
    const episodeNoColumn = "2";
    const startTimeColumn = "3";
    const endTimeColumn = "4";

    params.data.map((item) => {
      try {
        const numberRegex = /^[0-9]*$/gm;
        if (typeof item[programmeIdColumn] == "string" && !item[programmeIdColumn].match(numberRegex)) throw "programme_id is invalid";

        const row = {
          platform: item[platformColumn],
          programme_id: parseInt(item[programmeIdColumn]),
          episode_no: item[episodeNoColumn] == "all" ? "all" : item[episodeNoColumn].split(","), // episode_no can be 'all' or '1,2,3'
          start_time: moment(item[startTimeColumn], "YYYYMMDDHHmm"),
          end_time: moment(item[endTimeColumn], "YYYYMMDDHHmm"),
        };

        // console.log("createManyEpisodePreviewSchedules : row = ");
        // console.log(row);

        if ([NaN, null].includes(row.programme_id)) throw "programme_id is invalid";
        if (row.episode_no.length == 0) throw "episode_no is empty";
        if ([NaN, null].includes(row.episode_no[0])) throw "episode_no is null";

        // episode_no can be 'all' or '1,2,3'
        if (row.episode_no != "all") {
          row.episode_no = row.episode_no.map((ep) => {
            if (ep < 1) throw "episode_no[" + ep + "] must not less than 1"; // ep should not less than 1
            if (typeof ep == "string" && !ep.match(numberRegex)) throw "episode_no is not number"; // ep should be numeric

            return ep;
          });
          row.episode_no = row.episode_no
            .filter((item) => {
              const b = !item ? false : true;
              // console.log("filter row.episode_no : " + item + " -> " + (b ? "T" : "F"));

              return b;
            })
            .map((item) => {
              return parseInt(item);
            });
        }

        if (row.start_time._isValid == false) throw "start_time is invalid";
        if (row.end_time._isValid == false) throw "end_time is invalid";

        row.platform = row.platform ? row.platform.split(",") : ["web"];
        // if (!row.platform) throw "platform is empty";
        // if (!Array.isArray(row.platform)) throw "platform must be array";

        rows.push(row);
      } catch (e) {
        console.log(e);
      }
    });

    console.log("createManyEpisodePreviewSchedules : rows = ");
    console.log(rows);

    const data = {
      schedules: rows,
    };

    console.log("createManyEpisodePreviewSchedules : JSON.stringify(data) = ");
    console.log(JSON.stringify(data));

    const url = `${apiUrl}/episode-preview-schedules`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    console.log("createManyEpisodePreviewSchedules : json = ");
    console.log(json);

    return {
      data: json,
    };
  },

  createIMCannedMessage: async (resource, params) => {
    console.log("createIMCannedMessage");
    const data = {
      text: params.data.text,
    };

    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createIMCannedMessageList: async (params) => {
    console.log("createIMCannedMessageList");

    var cannedMessages = [];
    if (params.canned_messages) {
      for (const [i, value] of params.canned_messages.entries()) {
        var obj = {
          text: value.text,
          order: i,
          enabled: value.enabled,
        };

        if (!value.id) {
          //Create im-canned-message if not exist
          var result = await dataProvider.createIMCannedMessage("im-canned-messages", { data: { text: value.text } });
          obj["_id"] = result.data.id;
        } else {
          obj["_id"] = value.id;
        }

        cannedMessages.push(obj);
      }
    }

    const data = {
      name: params.name,
      question: params.question,
      tnc: params.tnc ?? "",
      start_time: params.start_time,
      end_time: params.end_time,
      canned_messages: cannedMessages,
      list_type: params.voting_enabled ? "vote" : "default",
    };

    const url = `${apiUrl}/im-canned-message-list`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });
    // eslint-disable-next-line
    // delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    // json = convert_IdToId(json);

    return {
      data: convert_IdToId(json),
    };
  },
  updateIMCannedMessageList: async (id, params) => {
    console.log("updateIMCannedMessageList");

    var cannedMessages = [];
    if (params.canned_messages) {
      for (const [i, value] of params.canned_messages.entries()) {
        var obj = {
          text: value.text,
          order: i,
          enabled: value.enabled,
        };

        //DO NOT PROVIDE _id for canned_message item in field "canned_messages" IF NOT PRESENT IN collection im-canned-message
        if (!value.id) {
          //Create im-canned-message if not exist
          var result = await dataProvider.createIMCannedMessage("im-canned-messages", { data: { text: value.text } });
          obj["_id"] = result.data.id;
        } else {
          obj["_id"] = value.id;
        }

        cannedMessages.push(obj);
      }
    }

    const data = {
      name: params.name,
      question: params.question,
      tnc: params.tnc ?? "",
      start_time: params.start_time,
      end_time: params.end_time,
      canned_messages: cannedMessages,
      list_type: params.voting_enabled ? "vote" : "default",
    };

    const url = `${apiUrl}/im-canned-message-list/${id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(convertIdTo_Id(data)),
    });
    // eslint-disable-next-line
    // delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    // json = convert_IdToId(json);

    return {
      data: convert_IdToId(json),
    };
  },

  createIMProducts: async (params) => {
    console.log("createIMProducts");

    var products = [];
    for (const [i, value] of params.entries()) {
      //product_image is URL if Clone chatroom
      var product_image;
      if (value.product_picture_file) {
        product_image = await blobToData(value.product_picture_file.rawFile);
      } else {
        product_image = value.product_image;
      }

      products.push({
        title: value.title,
        description: value.description,
        live_promote_text: value.live_promote_text,
        purchase_button_text: value.purchase_button_text,
        product_url: value.product_url,
        is_in_app_url: value.open_url == "in_app",
        // product_image: await blobToData(value.product_picture_file.rawFile),
        product_image: product_image,
        is_onshelf: value.is_onshelf,
        is_live_promote: value.is_live_promote,
      });
    }

    const data = {
      products: products,
    };

    console.log(data);

    const url = `${apiUrl}/im-room-products`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(convertIdTo_Id(data)),
    });

    return {
      data: convert_IdToId(json),
    };

    // return {
    //   data: convert_IdToId([
    //     {
    //       _id: "aaaaa",
    //     },
    //     {
    //       _id: "bbbbb",
    //     },
    //     {
    //       _id: "ccccc",
    //     },
    //   ]),
    // };
  },

  createIMIconList: async (params, start_time, end_time) => {
    console.log("createIMIconList");

    var icons = [];
    for (const [i, value] of params.entries()) {
      icons.push({
        _id: value.id,
        image_url: value.image_url,
        order: i,
        enabled: value.enabled,
      });
    }

    const data = {
      icons: icons,
      start_time: start_time,
      end_time: end_time,
      // start_time: "2023-03-09T10:22:04.574Z", //NOTE: UI doesn't have this value
      // end_time: "2030-03-09T10:22:04.574Z", //NOTE: UI doesn't have this value
    };

    const url = `${apiUrl}/im-icon-list`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(convertIdTo_Id(data)),
    });
    // eslint-disable-next-line
    // delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    return {
      data: convert_IdToId(json),
    };
  },
  updateIMIconList: async (id, params, start_time, end_time) => {
    console.log("updateIMIconList");

    var icons = [];
    for (const [i, value] of params.entries()) {
      icons.push({
        _id: value.id,
        image_url: value.image_url,
        order: i,
        enabled: value.enabled,
      });
    }

    const data = {
      icons: icons,
      start_time: start_time,
      end_time: end_time,
      // start_time: "2023-03-09T10:22:04.574Z", //NOTE: UI doesn't have this value
      // end_time: "2030-03-09T10:22:04.574Z", //NOTE: UI doesn't have this value
    };

    const url = `${apiUrl}/im-icon-list/${id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(convertIdTo_Id(data)),
    });
    // eslint-disable-next-line
    // delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

    return {
      data: convert_IdToId(json),
    };
  },
  createIMChatroom: async (resource, params) => {
    console.log("createIMChatroom");
    console.log(params.data);

    //1. Create im-canned-message-list
    var cannedMessageList = [];
    if (params.data.canned_message_list) {
      for (const obj of params.data.canned_message_list) {
        const imCannedMessageListItem = await dataProvider.createIMCannedMessageList(obj);
        cannedMessageList.push(imCannedMessageListItem.data);
      }
    }

    //2. Create im-icon-list
    var iconList = [];
    if (params.data.icons && params.data.icons.length > 0) {
      const imIconList = await dataProvider.createIMIconList(params.data.icons, params.data.start_time, params.data.end_time);
      iconList = [imIconList.data];
    }

    //3. Create im-product-list
    var productList = [];
    if (params.data.products && params.data.products.length > 0) {
      const imProducts = await dataProvider.createIMProducts(params.data.products);
      productList = imProducts.data.products;
    }

    //3.
    const canned_message_list_ids = cannedMessageList.map((e) => e.id);
    const icon_list_ids = iconList.map((e) => e.id);
    const room_products_ids = productList.map((e) => e.id);

    const data = {
      name: params.data.name,
      // channel_no: params.data.channel_no,
      channel_no: params.data.channel_no.split(",").map((cn) => parseInt(cn)),
      programme_id: params.data.programme_id,
      // programme_en: params.data.title_en,
      // programme_tc: params.data.title_tc,
      episode_no: params.data.episode_no ? parseInt(params.data.episode_no) : undefined,
      // episode_no: parseInt(params.data.episode_no),
      // video_id: params.data.video_id,
      //platform: ??,
      mode: params.data.mode && params.data.mode.length > 0 ? params.data.mode[0] : "default",
      platform: params.data.platform,
      qr_code_entry: params.data.qr_code_entry,
      start_time: params.data.start_time,
      end_time: params.data.end_time,
      enabled: true, //NOTE: API required, but UI doesn't have this input
      // canned_message_list: cannedMessageList,
      artist_message_color_code: params.data.artist_message_color_code,
      artist_message_background_color_code: params.data.artist_message_background_color_code,
      icon_list_ids: icon_list_ids,
      canned_message_list_ids: canned_message_list_ids,
      room_products_ids: room_products_ids,
      stats_config: {
        initial_popularity: params.data.initial_popularity ?? undefined,
        initial_likes: params.data.initial_likes ?? undefined,
        pop_min_growth_factor: params.data.pop_min_growth_factor ?? undefined,
        pop_max_growth_factor: params.data.pop_max_growth_factor ?? undefined,
        pop_min_reduction_coefficient: params.data.pop_min_reduction_coefficient ?? undefined,
        pop_max_reduction_coefficient: params.data.pop_max_reduction_coefficient ?? undefined,
        like_min_growth_factor: params.data.like_min_growth_factor ?? undefined,
        like_max_growth_factor: params.data.like_max_growth_factor ?? undefined,
        like_min_reduction_coefficient: params.data.like_min_reduction_coefficient ?? undefined,
        like_max_reduction_coefficient: params.data.like_max_reduction_coefficient ?? undefined,
      },

      // icon_list: iconList,
    };

    var d = convertIdTo_Id(data);

    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: "POST",
      headers: await getAuthHeaders(),
      body: JSON.stringify(convertIdTo_Id(data)),
    });

    //Note: If not setting this, UI shows error value.find is not a function
    //I think it's because mode is string, but we use Checkbox to display it (which expect an array)
    //https://github.com/marmelab/react-admin/issues/2527
    if (json.mode) {
      if (json.mode == "default") {
        json.mode = [];
      } else {
        json.mode = [json.mode];
      }
    }

    // eslint-disable-next-line
    // delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: convert_IdToId(json),
    };
  },

  updateIMChatroom: async (resource, params) => {
    console.log("updateIMChatroom");
    console.log(params.data);

    const roomStatus = getIMStatus(params.data.start_time, params.data.end_time);

    //1. Create im-canned-message-list
    var cannedMessageList = [];
    var iconList = [];
    var productList = [];

    //Only update or create if preview / live
    if (roomStatus != "end") {
      if (params.data.canned_message_list) {
        for (const obj of params.data.canned_message_list) {
          //Create new /im-canned-message-list if id doesn't exist
          if (!obj.id) {
            const imCannedMessageListItem = await dataProvider.createIMCannedMessageList(obj);
            cannedMessageList.push(imCannedMessageListItem.data);
          } else {
            const status = getIMStatus(obj.start_time, obj.end_time);

            //If the canned-messages is end, cannot update
            if (status != "end") {
              const imCannedMessageListItem = await dataProvider.updateIMCannedMessageList(obj.id, obj);
              cannedMessageList.push(imCannedMessageListItem.data);
            } else {
              cannedMessageList.push(obj);
            }
          }
        }
      }

      //2. Create im-icon-list
      if (params.data.icons) {
        //im-icon-list already exist, udpate it instead of create new one
        if (params.data.icon_list_id) {
          const imIconList = await dataProvider.updateIMIconList(params.data.icon_list_id, params.data.icons, params.data.start_time, params.data.end_time);
          iconList = [imIconList.data];
        } else {
          //Create new one if im-icon-list not exist
          if (params.data.icons.length > 0) {
            const imIconList = await dataProvider.createIMIconList(params.data.icons, params.data.start_time, params.data.end_time);
            iconList = [imIconList.data];
          }
        }
      }

      //3. im-product-list
      if (params.data.products) {
        for (const product of params.data.products) {
          if (product.isNew) {
            const result = await dataProvider.createIMProducts([product]);
            productList.push(result.data.products[0]);
          } else {
            const result = await dataProvider.updateIMProduct(product);
            productList.push(result.data.products[0]);
          }
        }
      }
    } else {
      if (params.data.canned_message_list) {
        for (const obj of params.data.canned_message_list) {
          cannedMessageList.push({ id: obj.id });
        }
      }
      if (params.data.icons) {
        if (params.data.icon_list_id) {
          iconList.push({ id: params.data.icon_list_id });
        }
      }
      if (params.data.products) {
        for (const obj of params.data.products) {
          productList.push({ id: obj.id });
        }
      }
    }

    //3.
    const canned_message_list_ids = cannedMessageList.map((e) => e.id);
    const icon_list_ids = iconList.map((e) => e.id);
    const room_products_ids = productList.map((e) => e.id);

    const data = {
      name: params.data.name,
      // channel_no: params.data.channel_no,
      channel_no: params.data.channel_no.split(",").map((cn) => parseInt(cn)),
      programme_id: params.data.programme_id,
      // programme_en: params.data.title_en,
      // programme_tc: params.data.title_tc,
      episode_no: params.data.episode_no ? parseInt(params.data.episode_no) : undefined,
      // episode_no: parseInt(params.data.episode_no),
      // video_id: params.data.video_id,
      //platform: ??,
      mode: params.data.mode && params.data.mode.length > 0 ? params.data.mode[0] : "default",
      platform: params.data.platform,
      qr_code_entry: params.data.qr_code_entry,
      start_time: params.data.start_time,
      end_time: params.data.end_time,
      enabled: true, //NOTE: API required, but UI doesn't have this input
      // canned_message_list: cannedMessageList,
      artist_message_color_code: params.data.artist_message_color_code,
      artist_message_background_color_code: params.data.artist_message_background_color_code,
      icon_list_ids: icon_list_ids,
      canned_message_list_ids: canned_message_list_ids,
      room_products_ids: room_products_ids,
      stats_config: {
        initial_popularity: params.data.initial_popularity ?? undefined,
        initial_likes: params.data.initial_likes ?? undefined,
        pop_min_growth_factor: params.data.pop_min_growth_factor ?? undefined,
        pop_max_growth_factor: params.data.pop_max_growth_factor ?? undefined,
        pop_min_reduction_coefficient: params.data.pop_min_reduction_coefficient ?? undefined,
        pop_max_reduction_coefficient: params.data.pop_max_reduction_coefficient ?? undefined,
        like_min_growth_factor: params.data.like_min_growth_factor ?? undefined,
        like_max_growth_factor: params.data.like_max_growth_factor ?? undefined,
        like_min_reduction_coefficient: params.data.like_min_reduction_coefficient ?? undefined,
        like_max_reduction_coefficient: params.data.like_max_reduction_coefficient ?? undefined,
      },

      // icon_list: iconList,
    };

    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(convertIdTo_Id(data)),
    });

    //Note: If not setting this, UI shows error value.find is not a function
    //I think it's because mode is string, but we use Checkbox to display it (which expect an array)
    //https://github.com/marmelab/react-admin/issues/2527
    if (json.mode) {
      if (json.mode == "default") {
        json.mode = [];
      } else {
        json.mode = [json.mode];
      }
    }

    // eslint-disable-next-line
    // delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: convert_IdToId(json),
    };
  },
  getProfilesPreferences: async (resource, params) => {
    const queryObj = {
      // sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: 1,
      limit: 99999,
      where: {
        profile_class: params.filter.profile_class,
      },
    };

    let json;
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/profiles-preferences?query=${query}`;

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    console.log(json);

    const d = json.data[0];

    //copy tag_id to id
    for (const tag of d.tags) {
      tag["id"] = tag.tag_id;
      tag["pp_id"] = d._id; //NOTE: DIRTY, but to give the _id to each element in order to update later
    }

    return d;
  },

  getListChannelOrdering: async () => {
    const queryObj = {
      sort: "order", // (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: 1,
      limit: 999999,
      // page: page,
      // limit: perPage,
    };

    const url = `${apiUrl}/channels?query=` + JSON.stringify(queryObj);
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },

  addVipoBlacklist: async (programmeId) => {
    const programmeData = await dataProvider.getProgrammeByProgrammeId(programmeId);

    if (programmeData && programmeData.data) {
      const programme = programmeData.data;

      const data = {
        programme_id: programmeId,
        name_tc: programme.name_tc,
        name_en: programme.name_en,
      };

      const url = `${apiUrl}/vipo-blacklist`;
      const { json } = await httpClient(url, {
        method: "POST",
        headers: await getAuthHeaders(),
        body: JSON.stringify(data),
      });

      // eslint-disable-next-line
      delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

      return {
        data: json,
      };
    }

    return {};
  },

  getManyMpmRows: async (resource, params) => {
    console.log("getManyMpmRows");
    console.log(params);
    var query = "";

    query = JSON.stringify({ limit: 99999, where: { _id: { $in: params.ids } } });

    const url = `${apiUrl}/${resource}?query=${query}`;
    console.log("getManyMpmRows : url = " + url);

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      //Because ref is keyword, we need to rename it
      delete Object.assign(item, { ["ref_x"]: item["ref"] })["ref"];
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];

      item.region = convertJsonToRegion(item);

      if (item["type"] == "editorial_pick") {
        if (item["ref_x"].startsWith("events")) {
          item["type"] = "event";
        }
      }
    }

    var items = json.data;

    return {
      data: items,
      total: json.total,
    };
  },
  getListMpmRows: async (resource, params) => {
    console.log("dataProvider . getListMpmRows : params = ");
    console.log(params);

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    const filterKeys = Object.keys(params.filter);
    if (filterKeys.length !== 0) {
      const filter = [];
      filterKeys.forEach((key) => {
        let expr;
        switch (typeof params.filter[key]) {
          case "string": {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = {
                $expr: {
                  $regexMatch: {
                    input: { $toString: `$${key}` },
                    regex: ".*" + _.escapeRegExp(params.filter[key]) + ".*",
                    options: "i",
                  },
                },
              };
            }

            break;
          }
          default: {
            if (key == "tags") {
              expr = { tags: params.filter[key] };
            } else {
              expr = { key: params.filter[key] };
            }
          }
        }
        filter.push(expr);
      });
      queryObj.where = { $and: filter };
    }

    let json;
    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/${resource}?query=${query}`;

    const response = await httpClient(url, {
      headers: await getAuthHeaders(),
    });
    json = response.json;

    let res = [];
    for (const item of json.data) {
      res.push(item);

      //Because ref is keyword, we need to rename it
      delete Object.assign(item, { ["ref_x"]: item["ref"] })["ref"];

      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];

      item.region = convertJsonToRegion(item);
    }

    console.log(json);

    return {
      data: res,
      total: json.total,
    };
  },
  getListMenuTree: async (resource, params) => {
    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    // var where = { enabled: params.filter.enabled };
    queryObj.where = {};
    // if (params.filter.enabled) {
    //   queryObj.where.enabled = params.filter.enabled;
    // }

    // if (params.filter.requested_to_vipo) {
    //   queryObj.where.requested_to_vipo = params.filter.requested_to_vipo;
    // }

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/menu-tree?query=${query}`;

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    for (const d of json.data) {
      for (const mt of d.menu_tree) {
        mt.region = convertJsonToRegion(mt);

        if (mt.categories) {
          for (const c of mt.categories) {
            c.region = convertJsonToRegion(c);
          }
        }
      }
    }

    return {
      data: convert_IdToId(json.data),
      total: json.total,
    };
  },

  getListVipoBlacklist: async (resource, params) => {
    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    // var where = { enabled: params.filter.enabled };
    queryObj.where = {};
    if (params.filter.enabled) {
      queryObj.where.enabled = params.filter.enabled;
    }

    if (params.filter.requested_to_vipo) {
      queryObj.where.requested_to_vipo = params.filter.requested_to_vipo;
    }

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/vipo-blacklist?query=${query}`;

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    return {
      data: convert_IdToId(json.data),
      total: json.total,
    };
  },
  getListProfileIcons: async (resource, params) => {
    var where = { profile_class: params.filter.profile_class };
    if (params.filter.series_tc) {
      where["series_tc"] = { $regex: params.filter.series_tc, $options: "i" };
    }
    if (params.filter.series_en) {
      where["series_en"] = { $regex: params.filter.series_en, $options: "i" };
    }
    if (params.filter.enabled) {
      where["enabled"] = params.filter.enabled;
    }
    if (params.filter.show_in_frontend) {
      where["show_in_frontend"] = params.filter.show_in_frontend;
    }

    const queryObj = {
      page: 1,
      limit: 99999,
      sort: "order",
      where: where,
    };

    const query = JSON.stringify(queryObj);
    const url = `${apiUrl}/profile-icons?query=${query}`;

    const { json } = await httpClient(url, {
      headers: await getAuthHeaders(),
    });

    return {
      data: convert_IdToId(json.data),
      total: json.total,
    };
    // console.log(json);

    // const d = json.data[0];

    // //copy tag_id to id
    // for (const tag of d.tags) {
    //   tag["id"] = tag.tag_id;
    //   tag["pp_id"] = d._id; //NOTE: DIRTY, but to give the _id to each element in order to update later
    // }

    // return d;
  },

  getListProfilesPreferences: async (resource, params) => {
    // console.log(params.filter);
    if (params.filter.allItems.length > 0) {
      return {
        data: params.filter.allItems,
        total: params.filter.allItems.length,
      };
      // return { data: [{ id: "1" }], total: 1 };
    }

    const d = await dataProvider.getProfilesPreferences(resource, params);

    var tags = d.tags;
    // if (params.filter.tag_id) {
    //   tags = tags.filter((t) => t.tag_id.toString().includes(params.filter.tag_id.toString()));
    // }
    // if (params.filter.name_tc) {
    //   tags = tags.filter((t) => t.name_tc.includes(params.filter.name_tc));
    // }
    // if (params.filter.name_en) {
    //   tags = tags.filter((t) => t.name_en.includes(params.filter.name_en));
    // }

    return {
      data: tags,
      total: tags.length,
    };
    // return {
    //   data: d.tags,
    //   total: d.tags.length,
    // };
  },

  getManyTagsAutocomplete: async (resource, params) => {
    console.log("getManyTagsAutocomplete");
    console.log(params);

    var where = { _id: { $in: params.ids } };
    var queryObj = { page: 1, limit: 99999 };
    queryObj.where = where;

    const url = `${apiUrl}/tags?query=` + JSON.stringify(queryObj);
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },
  getListTagsAutocomplete: async (resource, params) => {
    console.log("getListTagsAutocomplete");
    console.log(params);

    if (!("q" in params.filter)) {
      return { data: [], total: 0 };
    }

    var where = {};
    if (params.filter.q) {
      where = {
        $and: [
          {
            $or: [
              //
              { name_tc: { $regex: params.filter.q, $options: "i" } },
              { name_en: { $regex: params.filter.q, $options: "i" } },
            ],
          },
          {
            type: params.filter.tag_type,
          },
        ],
      };
    }

    var queryObj = { page: 1, limit: 99999 };
    queryObj.where = where;

    const url = `${apiUrl}/tags?query=` + JSON.stringify(queryObj);
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },

  getListIMCannedMessages: async (resource, params) => {
    console.log("getListIMCannedMessages");
    console.log(params);

    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    var query = {};
    if (params.filter.text) {
      query["text"] = {
        $regex: params.filter.text,
        $options: "i",
      };
      // query["text"] = ".*" + params.filter.text + ".*";
    }

    queryObj.where = query;

    const url = `${apiUrl}/im-canned-messages?query=` + JSON.stringify(queryObj);
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: json.data,
      total: json.total,
    };
  },

  getManyIMCannedMessages: async (resource, params) => {
    console.log("getManyIMCannedMessages");

    // var query = {};
    // if (params.filter.text) {
    //   query["text"] = {
    //     $regex: params.filter.text,
    //     $options: "i",
    //   };
    //   // query["text"] = ".*" + params.filter.text + ".*";
    // }

    // queryObj.where = query;

    const url = `${apiUrl}/im-canned-messages/${params.ids[0]}`;
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    if (json.data) {
      // for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(json.data, { ["id"]: json.data["_id"] })["_id"];
      // }

      return {
        data: [json.data],
        total: 1,
      };
    }
    return {
      data: [],
      total: 0,
    };
  },

  updateIMProduct: async (params) => {
    console.log("updateIMProduct");
    console.log(params);

    const product = {
      _id: params.id,
      title: params.title,
      description: params.description,
      live_promote_text: params.live_promote_text,
      purchase_button_text: params.purchase_button_text,
      product_url: params.product_url,
      is_in_app_url: params.open_url == "in_app",
      is_onshelf: params.is_onshelf,
      is_live_promote: params.is_live_promote,
    };

    if (params.product_picture_file) {
      product["product_image"] = await blobToData(params.product_picture_file.rawFile);
    } else {
      product["product_image"] = params.product_image;
    }

    const data = {
      products: [product],
    };

    // const url = `${apiUrl}/im-room-products/${params.id}`;
    const url = `${apiUrl}/im-room-products`;
    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    // eslint-disable-next-line
    // delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: convert_IdToId(json),
    };
  },

  updateIMCannedMessages: async (resource, params) => {
    console.log("updateIMCannedMessages");
    console.log(params);

    const data = { text: params.data.text };

    const url = `${apiUrl}/im-canned-messages/${params.id}`;

    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  updateProfilesPreference: async (items, profileClass, profilesPreferenceId) => {
    console.log("updateProfilesPreference");

    const data = { profile_class: [profileClass], tags: items };

    const url = `${apiUrl}/profiles-preferences/${profilesPreferenceId}`;

    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createProfilesPreference: async (resource, params) => {
    console.log("createProfilesPreference");
    console.log(params);

    const tagsResponse = await dataProvider.getOne("tags", { id: params.data.tag_id });
    const tag = tagsResponse.data;

    const profilesPreferencesData = await dataProvider.getProfilesPreferences("profiles-preferences", {
      filter: {
        profile_class: params.data.pc,
      },
    });

    //Check if tag exist already
    for (const t of profilesPreferencesData.tags) {
      if (t.tag_id == tag.tag_id) {
        throw Error("Tag has been added already");
      }
    }

    profilesPreferencesData.tags.push(tag);

    const data = { profile_class: profilesPreferencesData.profile_class, tags: profilesPreferencesData.tags };

    const url = `${apiUrl}/profiles-preferences/${profilesPreferencesData._id}`;

    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  createIMIcon: async (resource, params) => {
    console.log("createIMIcon");

    var responseData;
    for (const icon of params.data.icon_base64) {
      const data = {
        image_base64: await blobToData(icon.rawFile),
      };

      const url = `${apiUrl}/${resource}`;
      const { json } = await httpClient(url, {
        method: "POST",
        headers: await getAuthHeaders(),
        body: JSON.stringify(data),
      });

      // eslint-disable-next-line
      delete Object.assign(json, { ["id"]: json["_id"] })["_id"];

      responseData = json;
    }

    return {
      data: responseData,
    };
  },

  updateIMIcon: async (resource, params) => {
    console.log("updateIMIcon");
    console.log(params);

    const data = {
      image_base64: await blobToData(params.data.icon_base64.rawFile),
    };

    const url = `${apiUrl}/im-icons/${params.id}`;

    const { json } = await httpClient(url, {
      method: "PUT",
      headers: await getAuthHeaders(),
      body: JSON.stringify(data),
    });

    // eslint-disable-next-line
    delete Object.assign(json, { ["id"]: json["_id"] })["_id"];
    return {
      data: json,
    };
  },

  getAllIMIcons: async () => {
    const queryObj = {
      page: 1,
      limit: 99999,
    };
    const query = JSON.stringify(queryObj);

    const url = `${apiUrl}/im-icons?query=${query}`;
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: convert_IdToId(json.data), //,json.data,
      // data: json.data,
      total: json.total,
    };
  },

  getListIMChatrooms: async (resource, params) => {
    console.log("getListIMChatrooms");
    console.log(params);

    const { page, perPage } = params.pagination;

    const { field, order } = params.sort;
    const queryObj = {
      sort: (order === "DESC" ? "-" : "") + (field === "id" ? "_" : "") + field,
      page: page,
      limit: perPage,
    };

    var queryArr = [];

    if (params.filter.name) {
      queryArr.push({
        name: {
          $regex: params.filter.name,
          $options: "i",
        },
      });
      // query["name"] = {
      //   $regex: params.filter.name,
      //   $options: "i",
      // };
    }
    if (params.filter.id) {
      queryArr.push({
        $expr: {
          $regexMatch: {
            input: { $toString: `$_id` },
            regex: ".*" + _.escapeRegExp(params.filter.id) + ".*",
            options: "i",
          },
        },
      });

      // query["_id"] = {
      //   $regex: params.filter.id,
      //   $options: "i",
      // };
      // query["_id"] = params.filter.id;
    }
    if (params.filter.channel_no) {
      queryArr.push({ channel_no: parseInt(params.filter.channel_no) });
      // query["channel_no"] = parseInt(params.filter.channel_no);
    }
    if (params.filter.status) {
      queryArr.push({ status: params.filter.status });
      // query["status"] = params.filter.status;
    }

    if (queryArr.length > 0) {
      queryObj.where = { $and: queryArr };
    }
    const url = `${apiUrl}/im-rooms?query=` + JSON.stringify(queryObj);
    var { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    for (const item of json.data) {
      item.status = getIMStatus(item.start_time, item.end_time);

      if (item.mode) {
        if (item.mode == "default") {
          item.mode = [];
        } else {
          item.mode = [item.mode];
        }
      }

      if (item.canned_message_list) {
        for (const d of item.canned_message_list) {
          d.status = getIMStatus(d.start_time, d.end_time);
          d.voting_enabled = d.list_type == "vote";
        }
      }

      //Backward compatible to type = String
      if (Array.isArray(item.channel_no)) {
        item.channel_no = item.channel_no.join(",");
      }

      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    return {
      data: convert_IdToId(json.data),
      total: json.total,
    };
  },

  searchCannedMessagesByKeyword: async (params) => {
    console.log(`dataProvider . searchCannedMessagesByKeyword : params = ${params}`);

    if (Object.keys(params.filter).length == 0) {
      return { data: [], total: 0 };
    }

    //NOTE: Ignore, when create new item from autocomplete, it will create a API request with text '@@ra-create'. weird
    if (params.filter.text == "@@ra-create") {
      return { data: [], total: 0 };
    }

    var query = {
      page: 1,
      limit: 999999,
      where: {
        text: {
          $regex: params.filter.text,
          $options: "i",
        },
      },
    };

    const url = `${apiUrl}/im-canned-messages?query=` + JSON.stringify(query);

    console.log("url = " + url);

    const { json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    console.log("dataProvider . searchCannedMessagesByKeyword : json = ");
    console.log(json);

    for (const item of json.data) {
      // item.name_tc = item.name_tc + " - " + item.programme_id;
      // item._id = item.programme_id;
      // eslint-disable-next-line
      delete Object.assign(item, { ["id"]: item["_id"] })["_id"];
    }

    console.log("dataProvider . searchCannedMessagesByKeyword : result = ");
    console.log({
      data: json.data,
      total: json.total,
    });

    return {
      data: json.data,
      total: json.total,
    };
  },
};

function translatePlatformArray(arr) {
  if (arr.length == 0) return "n/a";
  return arr
    .map((item) => {
      return translatePlatform(item);
    })
    .join(", ");
}

function translatePlatform(text) {
  switch (text) {
    case "ios":
      return "iOS";
    case "android":
      return "Android";
    case "web":
      return "Web";
    case "stb":
      return "STB";
    case "android_tv":
      return "Android TV";
    case "samsung_tv":
      return "Samsung TV";
    case "lg_tv":
      return "LG TV";
    default:
      return text;
  }
}

export default dataProvider;
