import { Grid, Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { Button, CloneButton, Confirm, Create, Datagrid, DeleteWithConfirmButton, Edit, EditButton, Filter, FormDataConsumer, Labeled, Link, List, minValue, NumberField, NumberInput, required, SaveButton, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput, Toolbar, TopToolbar, useNotify, useRecordContext } from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { useForm } from "react-final-form";
import { PopupboxContainer } from "react-popupbox";
import dataProvider from "../../dataProvider";
import { MyDateField } from "../../MyDateField";
import { MyDateTimeInput } from "../../MyDateTimeInput";
import { PlatformCheckboxGroupInput, PlatformChipField } from "../component/platform-components";
import { QrCodeEntryCheckboxGroupInput, QrCodeEntryChipField } from "../component/qr-code-entry-components";
import { CannedMessageList, CannedMessageListShow } from "./components/canned-message-list";
import { EpisodeSearch } from "./components/episode-search";
import { IconList, IconListShow } from "./components/icon-list";
import { ShowProductDialogContent } from "./components/product-dialog";
import { ProductList, ProductListShow } from "./components/product-list";
import { ShoppingCartModeCheckboxGroupInput } from "./components/shopping-cart-mode-checkbox";

const StatusField = (props) => {
  const record = useRecordContext();

  var style = {};
  if (record.status == "live") {
    style = { color: "red" };
  }

  return <TextField {...props} source="status" style={style} />;
};

const StartNowButton = (props) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const record = useRecordContext();
  const notify = useNotify();

  return (
    <React.Fragment>
      <Button
        label="Start Now"
        disabled={["live", "end"].includes(record.status)}
        onClick={() => {
          setShowConfirm(true);
        }}
      />
      <Confirm
        isOpen={showConfirm}
        title="Are you sure you want to start this chat room now?"
        onConfirm={async () => {
          try {
            await dataProvider.emergencyStartEndChatroom(record.id, true, false);
            window.location.reload(true);
          } catch (error) {
            notify(error.message.toString(), "warning");
          }

          setShowConfirm(false);
        }}
        onClose={() => {
          setShowConfirm(false);
        }}
      />
    </React.Fragment>
  );
};

const EndNowButton = (props) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const record = useRecordContext();
  const notify = useNotify();

  return (
    <React.Fragment>
      <Button
        label="End Now"
        disabled={record.status != "live"}
        onClick={() => {
          setShowConfirm(true);
        }}
      />
      <Confirm
        isOpen={showConfirm}
        title="Are you sure you want to end this chat room now?"
        onConfirm={async () => {
          try {
            await dataProvider.emergencyStartEndChatroom(record.id, false, true);
            window.location.reload(true);
          } catch (error) {
            notify(error.message.toString(), "warning");
          }

          setShowConfirm(false);
        }}
        onClose={() => {
          setShowConfirm(false);
        }}
      />
    </React.Fragment>
  );
};

const CustomDelete = () => {
  const record = useRecordContext();
  if (record.is_used) {
    return <></>;
  }

  return <DeleteWithConfirmButton record={record} undoable={false} confirmTitle={"Delete " + record.text} />;
};

const IMChatroomFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Chat Room ID" source="id" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Channel No" source="channel_no" alwaysOn />
    <SelectInput
      label="Status"
      source="status"
      choices={[
        { id: "live", name: "Live" },
        { id: "preview", name: "Preview" },
        { id: "end", name: "End" },
      ]}
      alwaysOn
    />
  </Filter>
);

const ListActions = (props) => (
  <TopToolbar>
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/im-rooms/create`,
      }}
      label="+ CREATE"
    />
  </TopToolbar>
);

export const IMChatroomList = (props) => (
  <List {...props} sort={{ field: "created_at", order: "DESC" }} bulkActionButtons={false} filters={<IMChatroomFilter />} actions={<ListActions />}>
    <Datagrid>
      <TextField source="id" label="Chat Room ID" />
      <TextField source="name" label="Name" />
      <TextField source="channel_no" label="Channel No" />
      <StatusField label="Status" />
      <MyDateField source="start_time" showTime />
      <MyDateField source="end_time" showTime />
      <StartNowButton />
      <EndNowButton />
      <ShowButton />
      <EditButton />
      <CloneButton />
      {/* <CustomEdit /> */}
      {/* <CustomDelete /> */}
    </Datagrid>
  </List>
);

const IMChatroomTitle = ({ record }) => {
  return <span>Chat Room {record ? `${record.id}` : ""}</span>;
};

const EditToolbar = (props) => (
  <Toolbar
    {...props}
    style={{
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <SaveButton />
    <CustomDelete />
  </Toolbar>
);

const ChatroomEditForm = (props) => {
  const { record } = props;

  const validatePopularityGrowthFactor = (values, allValues) => {
    if (!isNaN(allValues.pop_min_growth_factor) && !isNaN(allValues.pop_max_growth_factor) && allValues.pop_min_growth_factor > allValues.pop_max_growth_factor) {
      return "Max must be greater than or equal to Min";
    }

    return undefined;
  };

  const validatePopularityReductionCoefficient = (values, allValues) => {
    if (!isNaN(allValues.pop_min_reduction_coefficient) && !isNaN(allValues.pop_max_reduction_coefficient) && allValues.pop_min_reduction_coefficient > allValues.pop_max_reduction_coefficient) {
      return "Max must be greater than or equal to Min";
    }

    return undefined;
  };

  const validateLikesGrowthFactor = (values, allValues) => {
    if (!isNaN(allValues.like_min_growth_factor) && !isNaN(allValues.like_max_growth_factor) && allValues.like_min_growth_factor > allValues.like_max_growth_factor) {
      return "Max must be greater than or equal to Min";
    }

    return undefined;
  };

  const validateLikesReductionCoefficient = (values, allValues) => {
    if (!isNaN(allValues.like_min_reduction_coefficient) && !isNaN(allValues.like_max_reduction_coefficient) && allValues.like_min_reduction_coefficient > allValues.like_max_reduction_coefficient) {
      return "Max must be greater than or equal to Min";
    }

    return undefined;
  };

  return (
    <>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <TextInput source="name" validate={required()} fullWidth disabled={["end"].includes(record.status)} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextInput label="Channel Number" source="channel_no" validate={required()} fullWidth disabled={["live", "end"].includes(record.status)} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <MyDateTimeInput label="Start Time" source="start_time" fullWidth validate={required()} disabled={["live", "end"].includes(record.status)} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <MyDateTimeInput label="End Time" source="end_time" fullWidth validate={required()} disabled={["end"].includes(record.status)} />
        </Grid>
      </Grid>

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            <EpisodeSearch formData={formData} />
          </>
        )}
      </FormDataConsumer>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <PlatformCheckboxGroupInput useDefaultValue={true} disabled={["live", "end"].includes(record.status)} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <QrCodeEntryCheckboxGroupInput disabled={["live", "end"].includes(record.status)} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={4}>
          <NumberInput label="Initial Popularity" source="initial_popularity" fullWidth min={0} validate={minValue(0)} disabled={["live", "end"].includes(record.status)} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <NumberInput label="Minimum growth factor" source="pop_min_growth_factor" fullWidth validate={validatePopularityGrowthFactor} disabled={["end"].includes(record.status)} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <NumberInput label="Maximum growth factor" source="pop_max_growth_factor" fullWidth validate={validatePopularityGrowthFactor} disabled={["end"].includes(record.status)} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={4}>
          <></>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <NumberInput label="Minimum reduction coefficient" source="pop_min_reduction_coefficient" fullWidth validate={validatePopularityReductionCoefficient} disabled={["end"].includes(record.status)} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <NumberInput label="Maximum reduction coefficient" source="pop_max_reduction_coefficient" fullWidth validate={validatePopularityReductionCoefficient} disabled={["end"].includes(record.status)} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={4}>
          <NumberInput label="Initial Likes" source="initial_likes" fullWidth min={0} validate={minValue(0)} disabled={["live", "end"].includes(record.status)} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <NumberInput label="Minimum growth factor" source="like_min_growth_factor" fullWidth validate={validateLikesGrowthFactor} disabled={["end"].includes(record.status)} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <NumberInput label="Maximum growth factor" source="like_max_growth_factor" fullWidth validate={validateLikesGrowthFactor} disabled={["end"].includes(record.status)} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={4}>
          <></>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <NumberInput label="Minimum reduction coefficient" source="like_min_reduction_coefficient" fullWidth validate={validateLikesReductionCoefficient} disabled={["end"].includes(record.status)} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <NumberInput label="Maximum reduction coefficient" source="like_max_reduction_coefficient" fullWidth validate={validateLikesReductionCoefficient} disabled={["end"].includes(record.status)} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          {/* ColorInput has many issues, cannot set initial value, cannot disable, cannot fullWidth, cannot validate, blablabla */}
          {["end"].includes(record.status) ? (
            <Labeled label="Artist message color code">
              <TextField source="artist_message_color_code" style={{ color: record.artist_message_color_code }} />
            </Labeled>
          ) : (
            <ColorInput className="MuiFormControl-fullWidth MuiFilledInput-root" source="artist_message_color_code" label="Artist message color code" validate={required()} isRequired={true} initialValue={record.artist_message_color_code ?? "#060F12"} />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          {/* ColorInput has many issues, cannot set initial value, cannot disable, cannot fullWidth, cannot validate, blablabla */}
          {["end"].includes(record.status) ? (
            <Labeled label="Artist message background color code">
              <TextField source="artist_message_background_color_code" style={{ color: record.artist_message_background_color_code }} />
            </Labeled>
          ) : (
            <ColorInput className="MuiFormControl-fullWidth MuiFilledInput-root" source="artist_message_background_color_code" label="Artist message background color code" validate={required()} isRequired={true} initialValue={record.artist_message_background_color_code ?? "#DBAD00"} />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <CannedMessageList chatroomId={record.id} roomStatus={record.status} {...props} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <IconList roomStatus={record.status} {...props} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <ShoppingCartModeCheckboxGroupInput disabled={["end"].includes(record.status)} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <ProductList chatroomId={record.id} roomStatus={record.status} {...props} />
        </Grid>
      </Grid>
    </>
  );
};

const ChatroomShowForm = (props) => {
  const { record } = props;

  const ProductListSummary = ({ products, ...props }) => {
    return (
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <Labeled label="Product List">
            <>
              {products.map((item, index) => {
                return <ShowProductDialogContent item={item} {...props} />;
              })}
            </>
          </Labeled>
        </Grid>
      </Grid>
    );
  };
  const RoomSummaryReport = ({ summary, ...props }) => {
    return (
      <>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <Labeled label="Statistic Report">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Actual (Accumulate unique count)</TableCell>
                    <TableCell>Display (Peak)</TableCell>
                  </TableRow>
                </TableHead>
                <TableRow>
                  <TableCell style={{ overflowWrap: "break-word" }}>Popularity</TableCell>
                  <TableCell style={{ overflowWrap: "break-word" }}>{summary.actual_num_of_users}</TableCell>
                  <TableCell style={{ overflowWrap: "break-word" }}>{summary.display_num_of_users}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ overflowWrap: "break-word" }}>Likes</TableCell>
                  <TableCell style={{ overflowWrap: "break-word" }}>{summary.actual_num_of_likes}</TableCell>
                  <TableCell style={{ overflowWrap: "break-word" }}>{summary.display_num_of_likes}</TableCell>
                </TableRow>
              </Table>
            </Labeled>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <Labeled label="Canned Message Click Rate">
              <>
                {summary.canned_message_stats?.map((set, index) => {
                  return (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Set of Name</TableCell>
                          <TableCell>Voting</TableCell>
                          <TableCell>Voting Question</TableCell>
                          <TableCell>Canned Message</TableCell>
                          <TableCell>Unique Click Rate</TableCell>
                          <TableCell>Total Click Rate</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableRow>
                        <TableCell rowSpan={set.canned_messages.length + 1}>{set.name}</TableCell>

                        <TableCell rowSpan={set.canned_messages.length + 1}>
                          {set.list_type == "vote" ? (
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="true">
                              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
                            </svg>
                          ) : (
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="false">
                              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                            </svg>
                          )}
                        </TableCell>

                        <TableCell rowSpan={set.canned_messages.length + 1}>{set.question}</TableCell>
                      </TableRow>
                      {set.canned_messages.map((item, index) => {
                        return (
                          <TableRow>
                            <TableCell style={{ overflowWrap: "break-word" }}>{item.text}</TableCell>
                            <TableCell style={{ overflowWrap: "break-word" }}>{item.unique_count}</TableCell>
                            <TableCell style={{ overflowWrap: "break-word" }}>{item.total_count}</TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                  );
                })}
              </>
            </Labeled>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <Labeled label="Icon Click Rate">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Icon</TableCell>
                    <TableCell>Click Rate</TableCell>
                  </TableRow>
                </TableHead>

                {record.icon_summaries?.map((item, index) => {
                  return (
                    <TableRow>
                      <TableCell style={{ overflowWrap: "break-word" }}>
                        <img src={item.image_url} style={{ height: "30px", width: "30px" }} />
                      </TableCell>
                      <TableCell style={{ overflowWrap: "break-word" }}>{item.count}</TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Labeled>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <SimpleShowLayout>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="ID">
            <TextField source="id" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Name">
            <TextField source="name" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Channel Number">
            <TextField source="channel_no" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <Labeled label="Start Time">
            <MyDateField showTime source="start_time" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Labeled label="End Time">
            <MyDateField showTime source="end_time" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Programme ID">
            <TextField source="programme_id" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Name in TC">
            <TextField source="caption_tc" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Name in EN">
            <TextField source="caption_en" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Episode No">
            <TextField source="episode_no" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Platform">
            <PlatformChipField />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="QR Code Entry">
            <QrCodeEntryChipField />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Initial Popularity">
            <NumberField source="initial_popularity" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Minimum growth factor">
            <NumberField source="pop_min_growth_factor" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Maximum growth factor">
            <NumberField source="pop_max_growth_factor" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={4}>
          <></>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Minimum reduction coefficient">
            <NumberField source="pop_min_reduction_coefficient" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Maximum reduction coefficient">
            <NumberField source="pop_max_reduction_coefficient" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Initial Likes">
            <NumberField source="initial_likes" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Minimum growth factor">
            <NumberField source="like_min_growth_factor" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Maximum growth factor">
            <NumberField source="like_max_growth_factor" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={4}>
          <></>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Minimum reduction coefficient">
            <NumberField source="like_min_reduction_coefficient" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Labeled label="Maximum reduction coefficient">
            <NumberField source="like_max_reduction_coefficient" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Artist message color code">
            <TextField source="artist_message_color_code" style={{ color: record.artist_message_color_code }} />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Artist message background color code">
            <TextField source="artist_message_background_color_code" style={{ color: record.artist_message_background_color_code }} />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <CannedMessageListShow source="canned_message_list" {...props} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <IconListShow source="icon_list" {...props} />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <ProductListShow source="products" {...props} />
        </Grid>
      </Grid>

      {record.room_summaries && Object.keys(record.room_summaries).length > 0 && <RoomSummaryReport summary={record.room_summaries} />}
      {record.products && Object.keys(record.products).length > 0 && <ProductListSummary products={record.products} />}
    </SimpleShowLayout>
  );
};

export const IMChatroomEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const CustomToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton label="SAVE" />
    </Toolbar>
  );

  return (
    <>
      <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<IMChatroomTitle />}>
        <SimpleForm redirect={redirectIMChatroom} toolbar={<CustomToolbar />}>
          <ChatroomEditForm />
        </SimpleForm>
      </Edit>
      <PopupboxContainer />
    </>
  );
};

const redirectIMChatroom = () => "/im-rooms";

export const IMChatroomCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  return (
    <>
      <Create {...props} onFailure={onFailure}>
        <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton={true} />} redirect={redirectIMChatroom}>
          <ChatroomCreateForm />
        </SimpleForm>
      </Create>
      <PopupboxContainer />
    </>
  );
};

const ChatroomCreateForm = (props) => {
  const { record } = props;
  const form = useForm();

  if (record) {
    delete record.created_at;
    delete record.modified_at;
    delete record.status;

    //Each im-room should have only one icon_list, Move icon_list to anothe variable
    if (record.icon_list) {
      if (record.icon_list.length > 0) {
        var iconList = record.icon_list[0];

        form.change("icons", iconList.icons); //Clone
      }

      delete record.icon_list;
    }

    //Add canned_mesasge status for convinient
    if (record.canned_message_list) {
      for (const item of record.canned_message_list) {
        item.status = "preview"; //Change status to preview
      }

      form.change("canned_message_list", record.canned_message_list);

      delete record.canned_message_list;
    }

    //Move stats_config out 1 level
    for (const key in record["stats_config"]) {
      record[key] = record["stats_config"][key];
    }

    delete record.stats_config;
  }

  console.log(record);

  return <ChatroomEditForm {...props} />;
};
export const IMChatroomShow = (props) => {
  return (
    <>
      <Show {...props} title={<IMChatroomTitle />}>
        <ChatroomShowForm />
      </Show>
      <PopupboxContainer />
    </>
  );
};
