import { Grid } from "@material-ui/core";
import { Color } from "@tiptap/extension-color";
import { TextStyle } from "@tiptap/extension-text-style";
import { ClearButtons, DefaultEditorOptions, FormatButtons, RichTextInput, RichTextInputToolbar, useTiptapEditor } from "ra-richtext-tiptap";
import React, { useEffect, useRef, useState } from "react";
import { Button, ImageField, ImageInput, Labeled, RadioButtonGroupInput, RecordContextProvider, required, RichTextField, SaveButton, SimpleForm, TextField, TextInput, Toolbar, useDataProvider, useNotify, useRedirect } from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxManager } from "react-popupbox";
var moment = require("moment-timezone");

const rgbToHex = (rgb) => {
  const result = rgb.match(/\d+/g);
  if (result) {
    return `#${((1 << 24) + (parseInt(result[0]) << 16) + (parseInt(result[1]) << 8) + parseInt(result[2])).toString(16).slice(1)}`;
  }
  return rgb;
};

//Note: Workaround, as the default color extension does not support custom default color
const CustomExtendedColor = Color.extend({
  addGlobalAttributes() {
    return [
      {
        types: ["heading", "paragraph", "textStyle"],
        attributes: {
          color: {
            default: "#ffffff", // custom default color
            // parseHTML: (element) => element.style.color?.replace(/['"]+/g, ""),
            parseHTML: (element) => {
              const color = element.style.color?.replace(/['"]+/g, "");

              //No idea why sometimes the color is in rgb format, so, always convert to hex
              return color.startsWith("rgb") ? rgbToHex(color) : color;
            },
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }

              return {
                style: `color: ${attributes.color}`,
              };
            },
          },
        },
      },
    ];
  },
});

const MyEditorOptions = {
  ...DefaultEditorOptions,
  extensions: [...DefaultEditorOptions.extensions, TextStyle, Color, CustomExtendedColor],
};

const MyRichTextInputToolbar = ({ size, ...props }) => {
  const editor = useTiptapEditor();

  return (
    <RichTextInputToolbar {...props}>
      <FormatButtons size={size} />
      <input type="color" onInput={(event) => editor.chain().focus().setColor(event.target.value).run()} value={editor.getAttributes("textStyle").color} data-testid="setColor" />
      {/* <AlignmentButtons size={size} /> */}
      {/* <ListButtons size={size} /> */}
      {/* <LinkButtons size={size} /> */}
      {/* <QuoteButtons size={size} /> */}
      <ClearButtons size={size} />
    </RichTextInputToolbar>
  );
};

const PopupContent = ({ form, item, ...props }) => {
  const endRef = useRef(null);
  const popupForm = useForm();

  //NOTE: DO NOT RENDER RichTextInput until form:description is ready. Otherwise it shows nothing in the editor
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    popupForm.change("title", item?.title);
    popupForm.change("description", item?.description);
    popupForm.change("live_promote_text", item?.live_promote_text);
    popupForm.change("purchase_button_text", item?.purchase_button_text);
    popupForm.change("product_url", item?.product_url);
    popupForm.change("open_url", item?.open_url);
    popupForm.change("product_image", item?.product_image);
    popupForm.change("product_picture_file", item?.product_picture_file);

    setIsInit(true);
  }, []);

  return (
    <>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <TextInput source="title" label="Title" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {isInit ? <RichTextInput className="product-dialog-description-input" toolbar={<MyRichTextInputToolbar size="large" />} editorOptions={MyEditorOptions} source="description" label="Description" validate={required()} fullWidth /> : ""}
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <TextInput source="live_promote_text" label="Live Promote Text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextInput source="purchase_button_text" label="Purchase Button Text" validate={required()} fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <TextInput source="product_url" label="URL" validate={required()} fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <RadioButtonGroupInput
            fullWidth
            source="open_url"
            validate={required()}
            choices={[
              { id: "in_app", name: "In-app" },
              { id: "out_app", name: "Out-app" },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          {item && item.product_image ? <img src={item.product_image} style={{ width: "100px" }} /> : ""}

          <ImageInput label="Product Picture" source="product_picture_file" accept="image/*" multiple={false} validate={item ? null : required()}>
            <ImageField source="src" />
          </ImageInput>
        </Grid>
      </Grid>
      <div ref={endRef} />
    </>
  );
};

const convertDescription = (content) => {
  console.log("Original content: ", content);

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  console.log(doc);

  // Process the parsed HTML
  const pElement = doc.querySelector("p");
  if (pElement) {
    const spanElements = [];
    pElement.childNodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (["SPAN"].includes(node.nodeName)) {
          // Check if the <span> has a style attribute and color property
          if (!node.style.color) {
            node.style.color = "#ffffff"; // Set default color
          }
          spanElements.push(node.outerHTML);
          // spanElements.push(node.outerHTML);
        }
        if (["S", "STRONG", "EM", "U"].includes(node.nodeName)) {
          spanElements.push(`<span style="color: #ffffff">${node.outerHTML}</span>`);
          // spanElements.push(node.outerHTML);
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        spanElements.push(`<span style="color: #ffffff">${node.textContent}</span>`);
      }
    });
    const newContent = spanElements.join("");
    console.log(newContent); // This is your new formatted HTML content }
    return newContent;
  }

  return content;
};
const CustomToolbar = ({ form, item, index, ...props }) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const popupForm = useForm();

  const handleSave = async (values, redirect) => {
    console.log("onSave");
    console.log(values);

    var oldData = form.getState().values.products ?? [];

    var newData = [...oldData]; //Clone

    const description = convertDescription(values.description);
    if (item) {
      //Update
      newData[index] = {
        id: item.id,
        title: values.title,
        description: description,
        live_promote_text: values.live_promote_text,
        purchase_button_text: values.purchase_button_text,
        product_url: values.product_url,
        open_url: values.open_url,
        product_picture_file: values.product_picture_file,
        product_image: values.product_picture_file ? values.product_picture_file.src : values.product_image,
        is_onshelf: item.is_onshelf,
        is_live_promote: item.is_live_promote,
        isNew: item.isNew,
      };
    } else {
      //Add
      newData.push({
        id: moment().unix().toString(),
        title: values.title,
        description: description,
        live_promote_text: values.live_promote_text,
        purchase_button_text: values.purchase_button_text,
        product_url: values.product_url,
        open_url: values.open_url,
        product_picture_file: values.product_picture_file,
        product_image: values.product_picture_file.src,
        is_onshelf: false,
        is_live_promote: false,
        isNew: true,
      });
    }

    form.change("products", newData);

    cleanPopupForm(popupForm);
    PopupboxManager.close();
  };

  return (
    <Toolbar {...props}>
      <Button
        onClick={() => {
          cleanPopupForm(popupForm);
          PopupboxManager.close();
        }}
        label="Close"
      ></Button>
      <SaveButton onSave={handleSave} label="Save" />
    </Toolbar>
  );
};

const cleanPopupForm = (popupForm) => {
  //   popupForm.change("set_name", undefined);
  //   popupForm.change("voting_question", undefined);
  //   popupForm.change("canned_message_start_time", undefined);
  //   popupForm.change("canned_message_end_time", undefined);
  //   popupForm.change("canned_messages", undefined);
  //   popupForm.change("voting_enabled", undefined);
};

export const AddProductDialog = ({ form, item, index, ...props }) => {
  return (
    <SimpleForm {...props} toolbar={<CustomToolbar item={item} index={index} form={form} />}>
      <PopupContent form={form} item={item} />
    </SimpleForm>
  );
};

export const ShowProductDialogContent = ({ item, ...props }) => {
  return (
    <>
      <RecordContextProvider value={item}>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={6}>
            <Labeled label="Title">
              <TextField source="title" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Labeled label="Description">
              <RichTextField className="product-dialog-description-field" source="description" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Labeled label="Live Promote Text">
              <TextField source="live_promote_text" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Labeled label="Purchase Button Text">
              <TextField source="purchase_button_text" />
            </Labeled>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={6}>
            <Labeled label="Product Picture">
              <ImageField id="product_image" source="product_image" fullWidth />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Labeled label="URL">
              <TextField source="product_url" />
            </Labeled>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={6}>
            <Labeled label="Open URL">
              <TextField source="open_url" />
            </Labeled>
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <Labeled label="Onshelf start time">
              <ol>
                {item.onshelf_start_times?.map((item, index) => {
                  return <li>{item}</li>;
                })}
              </ol>
            </Labeled>
          </Grid>
          <Grid item sm={12} md={6}>
            <Labeled label="Onshelf end time">
              <ol>
                {item.onshelf_end_times?.map((item, index) => {
                  return <li>{item}</li>;
                })}
              </ol>
            </Labeled>
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <Labeled label="Live Promote start time">
              <ol>
                {item.live_promote_start_times?.map((item, index) => {
                  return <li>{item}</li>;
                })}
              </ol>
            </Labeled>
          </Grid>
          <Grid item sm={12} md={6}>
            <Labeled label="Live Promote end time">
              <ol>
                {item.live_promote_end_times?.map((item, index) => {
                  return <li>{item}</li>;
                })}
              </ol>
            </Labeled>
          </Grid>
        </Grid>
      </RecordContextProvider>
    </>
  );
};

export const ShowProductDialog = ({ item, ...props }) => {
  return (
    <>
      <RecordContextProvider value={item}>
        <ShowProductDialogContent item={item} {...props} />
        <Button
          onClick={() => {
            PopupboxManager.close();
          }}
          label="Close"
        ></Button>
      </RecordContextProvider>
    </>
  );
};
